import { defineStore } from 'pinia'

type PreloaderControllerStoreState = {
  text: string;
}


/**
 * Preloader Controller Store Definitions
 *
 * @returns {StoreDefinition}
 */
export const usePreloaderControllerStore = defineStore({
  id: 'preloaderController',

  actions: {
    updatePreloader(message: string) {
      this.text = message;
    }
  },

  getters: {
    getMessage: (state) => state.text,
  },

  state: () => {
    const preloaderControllerState: PreloaderControllerStoreState = {
      text: '',
    };

    return preloaderControllerState;
  }
});
