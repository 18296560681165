
// Packages
import { defineComponent, PropType } from 'vue';

// Components
import DotsLoader from '../dots-loader/dots-loader.vue';

// Constants
import { typeOfState } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'CavuButton',

  components: { DotsLoader },

  props: {
    id: {
      default: '',
      type: String,
    },

    type: {
      default: 'submit',
      type: String as PropType<'submit' | 'reset' | 'button'>,
    },

    /** Controls what style of button to use */
    buttonType: {
      required: true,
      type: String as PropType<keyof typeof typeOfState>,
      validator(val: keyof typeof typeOfState) {
        return Object.keys(typeOfState).includes(val);
      },
    },

    prependIcon: {
      default: null,
      type: Object,
    },

    appendedIcon: {
      default: null,
      type: Object,
    },

    loading: {
      default: false,
      type: Boolean,
    },

    disabled: {
      default: false,
      type: Boolean,
    },

    isContentCentered: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    /** Adds a class to style as defined by buttonType property */
    getTypeOfStateClass(): string {
      return `button-${this.buttonType}`;
    },

    /** If we have a theme set the class */
    getEnvClass(): string {
      const { NUXT_ENV_THEME } = process.env;
      if (!NUXT_ENV_THEME) {
        return '';
      }
      return `button-${NUXT_ENV_THEME}`;
    },

    /** Sets the disabled styles if the button is disabled */
    getDisabledState(): string {
      if (!this.disabled) {
        return '';
      }
      return `button-${this.buttonType}--disabled`;
    },

    /** Works out how the content should be spaced based on state  */
    getJustifyClass(): string {
      if (!this.isContentCentered && !this.loading) {
        return 'justify-between';
      }
      return 'justify-center';
    },
  },

  mounted() {
    this.$emit('mounted');
  },

  /** Send a `btnClick` if the button is loaded */
  methods: {
    btnClick() {
      if (!this.loading) {
        this.$emit('btnClick');
      }
    },
  },
});
