
import { defineComponent } from 'vue';

// Components
import DotsLoader from '../dots-loader/dots-loader.vue';

export default defineComponent({
  components: {
    DotsLoader,
  },
  props: {
    contentPreloaderEnabled: {
      type: Boolean,
      required: true,
    },
  },
});
