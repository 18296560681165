
import { defineComponent } from 'vue'
import { smoothScroll } from '@white-label-helper/scroll'
import IconArrow from '@white-label-icon/icon-arrow'

export default defineComponent({
  name: 'ScrollTopButton',

  components: {
    IconArrow
  },

  methods: {
    scrollToTop () {
      smoothScroll('body')
    }
  }
})
