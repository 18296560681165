
import { defineComponent, PropType } from 'vue';
import { BARRIER_ACCESS_TYPES } from '@white-label-configuration/constants';
import imgError from '@white-label-helper/mixin-img-error';
import type { EntryMethod, InventoryItem } from '@white-label-types/parking-booking';

// Components
import ToggleContent from '../toggle-content/toggle-content.vue'

export default defineComponent({
  name: 'BTicketDirectionsInformation',

  components: {
    ToggleContent,
  },

  mixins: [imgError],

  props: {
    inventoryItem: {
      type: Object as PropType<InventoryItem>,
      required: true,
    },

    barrierAccess: {
      type: Object as PropType<EntryMethod['entryMethod']>,
      default: () => {},
    },
  },

  computed: {
    arrivalInfo(): string {
      return this.inventoryItem.entrance_info;
    },

    departureInfo(): string {
      return this.inventoryItem.exit_info;
    },

    displayAirportInformation(): boolean {
      return !!this.arrivalInfo || !!this.departureInfo || !!this.displayMap;
    },

    displayMap(): boolean {
      return !!this.inventoryItem.map_image?.src;
    },

    additionalInfoExpanded(): boolean {
      return this.barrierAccess?.type === BARRIER_ACCESS_TYPES.NONE;
    },
  },
});
