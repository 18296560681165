
// Packages
import { defineComponent } from 'vue';

// Helpers
import {
  goToApp,
  getDomainUrl,
  ECOMMERCE_URLS,
  DOMAINS_NAMES,
} from '@white-label-helper/switch-app';

// Constants
import { NAMED_ROUTES } from '@white-label-configuration/constants';

// Components
import Button from '../button/button.vue';
import TextButton from '../text-button/text-button.vue';

export default defineComponent({
  name: 'BookingsNoResults',

  components: {
    ControlButton: Button,
    TextButton,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      required: true,
    },

    displayButtons: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hideBookingButtons() {
      return this.$launchDarkly?.variation(
        'PREM-856-Aether-Hide-Booking-Buttons'
      );
    },
  },

  methods: {
    goToBooking(): void {
      return goToApp(
        getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
        ECOMMERCE_URLS.HOME
      );
    },
    goToProfileSettings(): void {
      this.$router.push(NAMED_ROUTES.my_profile);
    },
  },
});
