/* eslint-disable @typescript-eslint/ban-ts-comment */
// Types
import type { ClientConfig, ConfigOptions, RequestBody } from '@white-label-types/parking-checkout';

// Services
import { httpBooking } from '@white-label-configuration/http';
import { getAppVariable } from '@white-label-helper/get-app-variable';

import { PAYMENT_METHOD_STRATEGIES, PAYMENT_PROVIDERS } from '@white-label-configuration/constants';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';
import { myApp } from '@white-label-plugin/global-app-context';
import { datadogRum } from '@datadog/browser-rum';


// @ts-ignore
httpBooking.onRequest((config) => {
  const session_id = datadogRum?.getInternalContext()?.session_id || null;
  if (isBookingPortal) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${myApp.$auth?.user['https://user_metadata/channel_token']}`;
  }
  config.headers['X-Datadog-Session-ID'] = session_id;
});

const merchantId = process.env.NUXT_ENV_PAYMENT_MERCHANT_ID || '';
const token = process.env.NUXT_ENV_AFFILIATE_APPLICATION_TOKEN;
const newPaymentOnlyStrategy = process.env.NUXT_ENV_NEW_PAYMENT_ONLY_STRATEGY
  && process.env.NUXT_ENV_PAYMENT_PROVIDER === PAYMENT_PROVIDERS.STRIPE;

/**
 * * Constructs the request body for the /client-config endpoint based on the payment method.
 * @param windcaveConfig - Configuration options specific to the Windcave payment method.
 * @returns The request body object to be sent to the /client-config endpoint.
 */
const getRequestBody = (windcaveConfig: ConfigOptions): RequestBody => {
  const requestBody: RequestBody = {
    MerchantId: merchantId,
  };
  
  if (newPaymentOnlyStrategy) {
    requestBody.ClientConfigStrategyType = PAYMENT_METHOD_STRATEGIES.NEW_PAYMENT_ONLY;
  }
  
  if (windcaveConfig) {
    requestBody.WindcaveCreateClientConfigRequest = {
      Amount: windcaveConfig.amount,
      Currency: windcaveConfig.currency,
      LanguageCode: getAppVariable('default_language'),
      PartnerId: getAppVariable('partner_id'),
    }
  }
  
  return requestBody;
};

export const getClientConfig = (config: ConfigOptions): Promise<ClientConfig['result']> =>
  httpBooking
    .post('/payment-gateway/client-config', {
      headers: { Authorization: `Bearer ${token}` },
      body: getRequestBody(config),
    })
    .then(({ body }: { body: ClientConfig }) => body.result);
