
import { defineComponent } from 'vue';
import type { Partners } from '@white-label-types/partners';

import { getAppVariable } from '@white-label-helper/get-app-variable';

export default defineComponent({
  name: 'Logo',

  props: {
    imageUrl: {
      type: String,
      default: '',
    },

    homePageUrl: {
      type: String,
      default: '',
    },
  },

  computed: {
    logoClass(): string {
      const { NUXT_ENV_THEME = 'PARKINGS' } = process.env;

      const classMap = {
        PODS: 'logo__img-pods',
        PARKINGS: 'logo__img-parkings',
      };
      return classMap[NUXT_ENV_THEME];
    },

    homeUrlWithProtocol(): string | null {
      if (typeof this.homePageUrl !== 'string') {
        return null;
      }
      if (
        this.homePageUrl.startsWith('https://') ||
        this.homePageUrl.startsWith('http://')
      ) {
        return this.homePageUrl;
      }
      return `//${this.homePageUrl}`;
    },

    altText(): string {
      const partnerName: Partners['partner_name'] =
        getAppVariable('partner_name');
      return this.$t('layouts.logo.alt', { partnerName }) as string;
    },
  },
});
