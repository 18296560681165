
import { defineComponent } from 'vue';
import type { Partners } from '@white-label-types/partners';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import IconEnvelope from '@white-label-icon/icon-envelope';

export default defineComponent({
  name: 'FooterCustomerSupportContact',
  components: {
    IconEnvelope
  },

  data() {
    return (
      {
        isOpen: false,
      }
    );
  },

  computed: {
    customerSupportContact (): Partners['customer_support_contact_details'] {
      return getAppVariable('customer_support_contact_details');
    },

    customerSupportPhone (): string | null {
      return this.customerSupportContact?.phone_number ?? null;
    },

    customerSupportEmail (): string | null {
      return this.customerSupportContact?.email ?? null;
    },
  },
});
