
import { defineComponent } from 'vue';
import type VueI18n from 'vue-i18n';
import IconBack from '@white-label-icon/icon-back';

export default defineComponent({
  name: 'BackButton',

  components: {
    IconBack,
  },

  props: {
    isLogout: {
      type: Boolean,
    },
    buttonText: {
      type: String,
      default: 'back',
    },
  },

  computed: {
    backButtonText (): VueI18n.TranslateResult {
      const text = `UI.buttons.${this.buttonText}`;
      return this.$t(text);
    },
  },
});
