
import { defineComponent, PropType } from 'vue';
import type { PurchaseProduct, InventoryItem, Policy } from '@white-label-types/parking-booking';

import BTicket from '../b-ticket/b-ticket.vue';
import BTicketAdditionalInfo from '../b-ticket-additional-info/b-ticket-additional-info.vue';

interface ReceiptItem extends PurchaseProduct {
  inventory_item: InventoryItem;
  inventory_option: {
    cancellation_policies: Policy[];
    amendment_policies: Policy[];
  }
}

export default defineComponent({
  name: 'ReceiptParkings',

  components: {
    BTicket,
    BTicketAdditionalInfo: BTicketAdditionalInfo,
  },

  props: {
    hideAccessSection: {
      type: Boolean,
    },

    hideLocationInfo: {
      type: Boolean,
    },

    displayCancellationPolicy: {
      type: Boolean,
      default: true,
    },

    item: {
      type: Object as PropType<ReceiptItem>,
      required: true,
    },

    orderReference: {
      type: String,
      default: '',
    },

    isEntryExitProduct: {
      type: Boolean,
      default: false,
    },
  },
});
