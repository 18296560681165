
import { defineComponent, PropType } from 'vue';
import BTicketAdditionalInfo from '../b-ticket-additional-info/b-ticket-additional-info.vue';
import BTicket from '../b-ticket/b-ticket.vue';
import type { PurchaseProduct, InventoryItem, Policy, EntryMethod } from '@white-label-types/parking-booking';

interface ReceiptItem extends PurchaseProduct {
  inventory_item: InventoryItem;
  inventory_option: {
    cancellation_policies: Policy[];
    amendment_policies: Policy[];
  },
  user?: string,
  entry_methods?: EntryMethod[],
}

export default defineComponent({
  name: 'ManageBookingReceiptLounges',

  components: {
    BTicket,
    BTicketAdditionalInfo,
  },

  props: {
    hideAccessSection: {
      type: Boolean,
    },

    hideLocationInfo: {
      type: Boolean,
    },

    displayCancellationPolicy: {
      type: Boolean,
      default: true,
    },

    item: {
      type: Object as PropType<ReceiptItem>,
      required: true,
    },

    orderReference: {
      type: String,
      default: '',
    },
  },
  computed: {
    cancellationPolicies(): Policy[] {
      if (this.item.cancellation_protection) {
        return this.item.cancellation_protection.cancellation_policies;
      }
      return this.item.inventory_option.cancellation_policies;
    },
  },
});
