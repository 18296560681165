
import { defineComponent, PropType } from 'vue';
import { createGoogleMapLink } from '@white-label-helper/create-googlemap-link';
import type { GeoLocation } from '@white-label-types/location';

import IconLocation from '@white-label-icon/icon-location'

export default defineComponent({
  name: 'BTicketLocationInfo',

  components: {
    IconLocation
  },

  props: {
    coordinates: {
      type: Object as PropType<Pick<GeoLocation, 'latitude' | 'longitude'>>,
      required: true,
    },
  },

  computed: {
    googleMapsLink(): string | null {
      const lat = this.coordinates?.latitude;
      const long = this.coordinates?.longitude;

      return createGoogleMapLink(lat, long);
    },
  },
});
