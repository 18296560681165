import { defineStore } from 'pinia';

import type {
  GenerateProduct,
  ExtrasCancellationProtectionProduct,
} from '@white-label-types/parking-booking';

type ProductsType = GenerateProduct | ExtrasCancellationProtectionProduct;

export type MultiBasketStoreState = {
  showBasketSlideOver: boolean;
  storeBasketDot: boolean;
  continueClicked: boolean;
  latestBasketItem: null | ProductsType;
  showBasketNotification: boolean;
};

/**
 * Multi basket store definition
 *
 * @returns {StoreDefinition}
 */
export const useMultiBasketStore = defineStore({
  id: 'multiBasket',

  actions: {
    commitContinueClicked(payload: boolean) {
      this.continueClicked = payload;
    },

    commitShowBasketSlideOver(payload: boolean) {
      this.showBasketSlideOver = payload;
    },

    commitStoreBasketDot(payload: boolean) {
      this.storeBasketDot = payload;
    },

    commitShowLatestBasketItem (payload: ProductsType, show: boolean = true) {
      this.latestBasketItem = payload

      if (show) {
        this.showBasketNotification = true
      }
    },

    commitShowBasketNotification(payload: boolean ) {
      this.showBasketNotification = payload;
    },
  },

  getters: {
    readContinueClicked: (state) => state.continueClicked,
    readLatestBasketItem: (state) => state.latestBasketItem,
    readShowBasketNotification: (state) => state.showBasketNotification,
    readShowBasketSlideOver: (state) => state.showBasketSlideOver,
    readStoreBasketDot: (state) => state.storeBasketDot,
  },

  state: (): MultiBasketStoreState => ({
    continueClicked: false,
    latestBasketItem: null,
    showBasketNotification: false,
    showBasketSlideOver: false,
    storeBasketDot: true,
  }),
})
