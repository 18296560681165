
// Packages
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BTicketPrivateLounge',
  components: {
    IconPlane: () => import('@white-label-icon/icon-plane'),
    IconBaggage: () => import('@white-label-icon/icon-baggage'),
    IconCabinBaggage: () => import('@white-label-icon/icon-cabin-baggage'),
    IconRestaurant: () => import('@white-label-icon/icon-restaurant'),
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baggageTypes: {
        checked_baggage: {
          icon: 'IconBaggage',
          text: this.$t('privateLoungeFeatures.baggage.checked_baggage'),
        },
        cabin_baggage: {
          icon: 'IconCabinBaggage',
          text: this.$t('privateLoungeFeatures.baggage.cabin_baggage'),
        },
      },
      mealTypes: {
        carte_menu: {
          icon: 'IconRestaurant',
          text: this.$t('privateLoungeFeatures.meals.carte_menu'),
        },
        no_food_included: {
          icon: 'IconRestaurant',
          text: this.$t('privateLoungeFeatures.meals.no_food_included'),
        },
      },
    };
  },
  computed: {
    inventoryItem() {
      return this.product?.inventory_item || this.product?.inventoryItem;
    },
    inventoryItemDetails() {
      return this.product?.inventory_item_details || this.product?.inventoryItem;
    },
  },
});
