
import { defineComponent } from 'vue';
import StickySidebar from '../sticky-sidebar/sticky-sidebar.vue';

export default defineComponent({
  name: 'ManageBookingReceiptWrapper',

  components: {
    StickySidebar,
  },
});
