import { Instance } from 'flatpickr/dist/types/instance';

type CustomFlatpickrInstance = Instance & { yearSelectContainer: HTMLElement };

export default function yearDropdownPlugin() {
  const createSelectElement = function (
    yearDropdown: HTMLSelectElement,
    selectedYear: number,
    minYear: number,
    maxYear: number
  ) {
    yearDropdown.innerHTML = '';

    for (let i = maxYear; i >= minYear; i--) {
      const option = document.createElement('option');
      option.value = i.toString();
      option.text = i.toString();
      yearDropdown.appendChild(option);
    }

    yearDropdown.value = selectedYear.toString();
  };

  return function (fp: CustomFlatpickrInstance) {
    const yearDropdown = document.createElement('select');

    fp.yearSelectContainer = fp._createElement('div', `flatpickr-year-select`);
    fp.yearSelectContainer.tabIndex = -1;

    const updateYearDropdown = function () {
      const minYear = fp.config.minDate
        ? fp.config.minDate.getFullYear()
        : new Date().getFullYear() - 100;
      const maxYear = fp.config.maxDate
        ? fp.config.maxDate.getFullYear()
        : new Date().getFullYear() + 2;

      createSelectElement(yearDropdown, fp.currentYear, minYear, maxYear);
    };

    return {
      onReady: function () {
        updateYearDropdown();

        yearDropdown.addEventListener('change', function (evt) {
          const selectedYear = (evt.target as HTMLSelectElement).value;
          fp.changeYear(parseInt(selectedYear));
        });

        const numInputWrapper = fp.monthNav.querySelector(
          '.flatpickr-current-month .numInputWrapper'
        );
        if (numInputWrapper) {
          numInputWrapper.remove();
        }

        const currentMonthContainer = fp.monthNav.querySelector(
          '.flatpickr-current-month'
        );
        if (currentMonthContainer) {
          currentMonthContainer.appendChild(fp.yearSelectContainer);
        }

        fp.yearSelectContainer.append(yearDropdown);
      },
      onMonthChange: function () {
        updateYearDropdown();
      },
    };
  };
}
