
// Packages
import { defineComponent, PropType } from 'vue';

// Components
import TicketPreloader from '../ticket-preloader/ticket-preloader.vue';
import BTicketBookingDetails from '../b-ticket-booking-details/b-ticket-booking-details.vue';
import BTicketAccessInfoGuests from '../b-ticket-access-info-guests/b-ticket-access-info-guests.vue';
import IconInventory from '@white-label-icon/icon-inventory';

// Types
import type {
  ReceiptProduct,
  PurchaseProduct,
} from '@white-label-types/parking-booking';

// Constants
import { BARRIER_ACCESS_TYPES } from '@white-label-configuration/constants';
import ProductTags from '../product-tags/product-tags.vue';

export default defineComponent({
  name: 'BTicketMultiBasket',
  components: {
    ProductTags,
    TicketPreloader,
    IconInventory,
    IconArrowDown: () => import('@white-label-icon/icon-arrow-down'),
    BTicketBookingDetails,
    BTicketAccessInfoGuests,
  },

  props: {
    ticketData: {
      type: Object as PropType<ReceiptProduct | PurchaseProduct>,
      required: true,
    },

    orderReference: {
      type: String,
      default: '',
    },

    entryTime: {
      type: String,
      default: '',
    },

    exitTime: {
      type: String,
      default: '',
    },

    hideAccessSection: {
      type: Boolean,
      default: false,
    },

    accessInfoFromEntranceInfo: {
      type: Boolean,
      default: false,
    },

    contentLoading: {
      type: Boolean,
    },

    subItems: {
      type: Array,
      default() {
        return [];
      },
    },

    oneItem: {
      type: Boolean,
      default: false,
    },

    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    isAccessInfoExists(): boolean {
      return (
        (Array.isArray(this.ticketData?.entry_methods) &&
          this.ticketData?.entry_methods.length) ||
        (this.accessInfoFromEntranceInfo &&
          this.ticketData?.inventory_item?.entrance_info)
      );
    },
    productName(): string {
      if ('parkingName' in this.ticketData) return this.ticketData.parkingName;
      if ('inventory_item' in this.ticketData)
        return this.ticketData.inventory_item?.display_name;
      return '';
    },
    airportName(): string {
      let name = '';
      let code = '';
      if ('locationInfo' in this.ticketData) {
        name = this.ticketData.locationInfo?.name;
        code = this.ticketData.locationInfo?.code;
      } else if ('poi' in this.ticketData) {
        name = this.ticketData.poi?.name;
        code = this.ticketData.poi?.code;
      }

      return `${name} (${code})`;
    },
    ticketEntryTime(): string {
      // @ts-ignore
      return (
        this.entryTime || `${this.ticketData?.criteria?.started_at?.datetime}`
      );
    },
    ticketExitTime(): string {
      // @ts-ignore
      return (
        this.exitTime || `${this.ticketData?.criteria?.closed_at?.datetime}`
      );
    },
    ticketEntryDateTime(): string {
      return this.ticketData.entryDateTime;
    },
    ticketExitDateTime(): string {
      return this.ticketData.exitDateTime;
    },
    // Temporary fix
    subOptions(): any[] {
      if (this.subItems.length > 0) {
        return this.subItems;
      }

      // @ts-expect-error - Type might be incorrect. This code is handling that edge case
      if (this.ticketData?.inventory_option !== undefined) {
        // @ts-expect-error - Type might be incorrect. This code is handling that edge case
        return this.getSubItems(this.ticketData?.inventory_option?.sub_options);
      }

      // This is the expected structure according to the Type value.
      return this.getSubItems(
        this.ticketData?.items?.[0]?.inventory_option?.sub_options
      );
    },
    showDragonPassLogo() {
      const dragonPassChannelId = 59;
      return (
        this.ticketData.inventory_option?.booking_request?.channel_id ===
        dragonPassChannelId
      );
    },
    iconName() {
      return this.ticketData?.product_code;
    },
  },
  mounted() {
    if (this.oneItem) this.isOpen = true;
  },
  methods: {
    getSubItems(data: any) {
      return data?.map(
        (item: any) =>
          `${item?.totals?.quantity} x ${this.capitalize(item?.details?.name)}`
      );
    },
    capitalize(item: string): string {
      return item.charAt(0).toUpperCase() + item.slice(1);
    },
    displayAccessSection(accessType: string): boolean {
      return (
        !this.hideAccessSection && accessType === BARRIER_ACCESS_TYPES.QRCODE
      );
    },
  },
});
