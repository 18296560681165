// TODO Need to delete when dev will moved to hosting, which supports Nuxt middleware
import Vue from 'vue';
import { mapActions, mapState } from 'pinia';
import { datadogRum } from '@datadog/browser-rum';
import { UNSUPPORTED_BROWSERS, NAMED_ROUTES } from '@white-label-configuration/constants';
import { useHelpersStore } from '@white-label-store/helpers';

// This isn't used as a mixin but more like a function that is called in the mounted hook
Vue.mixin({

  computed: {
    ...mapState(useHelpersStore, {
      readIsAppMounted: 'readIsAppMounted',
    }),
    isAppMounted() {
      return this.readIsAppMounted;
    },
  },

  methods: {
    ...mapActions(useHelpersStore, {
      commitAppInitialized: 'commitAppInitialized',
    }),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async mountedCheckerHelper(this: any): Promise<void> {
      await this.browserSupportCheck();

      this.checkMaintenanceMode();
    },

    checkMaintenanceMode() {
      const maintenanceMode = process.env.NUXT_ENV_MAINTENANCE_MODE || '0';
      const isMaintenance = +maintenanceMode === 1;

      if (isMaintenance) {
        this.$router.push(NAMED_ROUTES.maintenance)
          .catch(() => {
            datadogRum.addError(new Error('Maintenance mode redirect error'));
          });
      }

      if (!isMaintenance && this.$route.fullPath.includes(NAMED_ROUTES.maintenance)) {
        this.$router.push(NAMED_ROUTES.home)
          .catch(() => {
            datadogRum.addError(new Error('Error on redirect to home page'));
          });
      }
    },

    async browserSupportCheck(): Promise<void> {
      /* eslint no-underscore-dangle: 0 */
      // @ts-expect-error - Can't see $ua property
      const isBrowserUnSupported: boolean = UNSUPPORTED_BROWSERS.includes(this.$ua._parsed.name);
      const isUnSupportedPage: boolean = this.$route.name === 'unsupported-browser';

      if (isBrowserUnSupported && !isUnSupportedPage) {
        this.commitAppInitialized(false);
        await this.$router.push({ name: 'unsupported-browser' });
      } else if (!isBrowserUnSupported && isUnSupportedPage) {
        this.commitAppInitialized(false);
        await this.$router.push({ name: 'index' });
      }

      this.commitAppInitialized(true);
    },
  },
});
