
import { defineComponent, PropType } from 'vue';
import type { Discount, Tax, Fee } from '@white-label-types/parking-checkout';
import { formatPrice } from '@white-label-helper/helper-payment';
import SummaryProductItem from '../summary-product-item/summary-product-item.vue';
import SummaryProductHeader from './summary-product-header.vue';
import SummaryPreloader from '../summary-preloader/summary-preloader.vue';

type SubItem = {
  title: string;
  value: string;
  isDiscount?: boolean;
}

export default defineComponent({
  name: 'SummaryProduct',

  components: {
    SummaryProductHeader: SummaryProductHeader,
    SummaryProductItem:  SummaryProductItem,
    SummaryPreloader
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    total: {
      type: String,
      default: '',
    },
    subtotal: {
      type: String,
      default: '',
    },
    subItems: {
      type: Array as PropType<SubItem[]>,
      default: () => [],
    },
    discount: {
      type: Object as PropType<Discount>,
      default: () => {},
    },
    taxes: {
      type: Array as PropType<Tax[]>,
      default: () => [],
    },
    fees: {
      type: Array as PropType<Fee[]>,
      default: () => [],
    },
    hasMobileHandler: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isExpanded: false,
    };
  },

  computed: {
    hasProductItems (): boolean {
      return !!this.fees.length || !!this.taxes.length || !!this.discount?.amount;
    },

    displayProductItems (): boolean {
      return this.subItems.length > 0 ||
        (this.hasProductItems && (this.isExpanded || !this.isMobileResolution));
    },

    itemPrice (): string {
      const hasDiscount: false | string = this.discount?.amount > 0 && this.discount.subtotalFormatted;
      return hasDiscount ? this.discount.subtotalFormatted : this.total;
    },
  },
  methods: {
    onClickHandler () {
      this.isExpanded = !this.isExpanded;
      if (this.hasMobileHandler) { this.$emit('on-click', this.isExpanded); }
    },
    priceFormat (price: number): Intl.NumberFormat | string {
      return formatPrice(price);
    },
  },
});
