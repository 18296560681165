export function smoothScroll(elem: string) {
  setTimeout(() => {
    document.querySelector(elem)?.scrollIntoView({
      behavior: 'smooth',
    });
  }, 0);
}

/**
 * Cross browser method in replacement of scrolling methods with { behavior: smooth }.
 * @param scrollableElement - DOM node, nearest scrollable parent for the target element.
 * Can be found with findScrollableParent method.
 * @param {Number} targetPosition - the position in px, to which scrollable element should be scrolled.
 * @param {Number} defaultPosition - (optional) the default position of scroll for scrollableElement. Useful for
 * accordion-like structures where need to scroll to some position after opening content.
 */
export function smoothScrolling(scrollableElement: HTMLElement, targetPosition: number, defaultPosition?: number) {
  // this is for avoid bug with saving of the scroll position
  if (defaultPosition) {
    scrollableElement.scrollTo(0, defaultPosition);
  }
  const scrollToTarget = () => {
    let scrollHeight = scrollableElement.scrollTop;
    // start scroll position to prevent infinite loop if the scrollableElement before expand has no scroll
    if (scrollHeight === 0) {
      scrollHeight = targetPosition / 3;
    }
    // smooth scrolling of the scrollableElement to the targetPosition.
    // scrolling speed is 1/10 current scroll height of the scrollableElement
    if (scrollHeight < targetPosition) {
      scrollableElement.scrollTo(0, scrollHeight + scrollHeight / 10);
      window.requestAnimationFrame(scrollToTarget);
    }
  };
  scrollToTarget();
}

/**
 * Find nearest scrollable parent method
 * @param element - DOM node, target element
 * @returns DOM node - nearest scrollable parent of the element
 */
export function findScrollableParent(el: HTMLElement): HTMLElement {
  let isBody;
  let hasScrollableSpace;
  let hasScrollOverflow;
  let currentElement = el;

  do {
    if (currentElement.parentNode) {
      currentElement = currentElement.parentNode as HTMLElement;
      // set condition variables
      isBody = currentElement === document.body;
      hasScrollableSpace = el.clientHeight <= el.scrollHeight;
      hasScrollOverflow = window.getComputedStyle(el, null).overflowY === 'scroll';
    }
  } while (!isBody && !(hasScrollableSpace && hasScrollOverflow));

  isBody = null;
  hasScrollableSpace = null;
  hasScrollOverflow = null;
  return currentElement;
}
