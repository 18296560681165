
import { defineComponent } from 'vue';

import { Locale } from '@white-label-types/internationalization';

import { IconWrapper, ModalCenter }  from 'ui-shared-components';
import LanguageSelector from '../language-selector/language-selector.vue';

export default defineComponent({
  name: 'ModalLanguageSelector',

  components: {
    IconFlag: () => import('@white-label-icon/icon-flag'),
    IconWrapper,
    LanguageSelector,
    ModalCenter,
  },

  props: {
    languages: {
      type: Array,
      required: true,
    },
    selectedLanguage: {
      type: String,
      required: true,
    },
  },

  methods: {
    changeLanguage(locale: Locale) {
      this.$i18n.setLocale(locale.code);
      this.closeModal();
    },

    closeModal() {
      this.$emit('close');
    },
  },
});
