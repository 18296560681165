
// Packages
import { defineComponent, PropType } from 'vue';

// Helpers
import {
  ACCOUNT_URLS,
  AccountURL,
  DOMAINS_NAMES,
  ECOMMERCE_URLS,
  getDomainUrl,
  goToApp
} from '@white-label-helper/switch-app';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import multiBasket from '@white-label-helper/mixin-multi-basket';
// Types
import type { Partners } from '@white-label-types/partners-api';
import type { DesktopMenuData } from '../user-menu-desktop/user-menu-desktop.vue';
import UserMenuDesktop from '../user-menu-desktop/user-menu-desktop.vue';
import type { MobileMenuData, TabData } from '../user-menu-mobile/user-menu-mobile.vue';
import UserMenuMobile from '../user-menu-mobile/user-menu-mobile.vue';
import type { Locale } from '@white-label-types/internationalization';
import { COLOUR_SCHEMES, ROUTE_NAMES, APP_HERO_PRODUCTS, HeroProduct } from '@white-label-configuration/constants';
import { setupLinkProtocol } from '@white-label-helper/setup-link-protocol';
// Components
import { BrandLogo, CavuButton } from 'ui-shared-components';
import NavbarTabs from '../navbar-tabs/navbar-tabs.vue';
import PartnerLink from '../navbar-partner-link/navbar-partner-link.vue';
import LanguageSelectorButton from '../language-selector-button/language-selector-button.vue';
import NavbarBasket from '../navbar-basket/navbar-basket.vue';

export type MenuDataOrUndefined = DesktopMenuData | MobileMenuData | undefined;

export type TabDataOrNull = TabData | null;

type ColourScheme = (typeof COLOUR_SCHEMES)[keyof typeof COLOUR_SCHEMES];

export default defineComponent({
  name: 'Navbar',

  components: {
    BrandLogo,
    CavuButton,
    LanguageSelectorButton,
    NavbarTabs,
    PartnerLink,
    UserMenuDesktop,
    UserMenuMobile,
    NavbarBasket,
  },

  mixins: [multiBasket],

  props: {
    logoRedirectURL: {
      required: true,
      type: String as PropType<Partners['logo']>,
    },

    logoURL: {
      required: true,
      type: String as PropType<Partners['logo']>,
    },

    partnerName: {
      required: true,
      type: String as PropType<Partners['partner_name']>,
    },

    showLoginFlow: {
      default: true,
      type: Boolean,
    },

    tabsData: {
      default: () => null,
      type: Object as PropType<TabDataOrNull>,
      required: false,
    },
  },

  computed: {
    /** The URL to send a customer when clicking on the logo */
    homePageUrl(): string | null {
      const discountFromQuery = this.$route.query['discount'];

      let redirectURL = this.$props.logoRedirectURL;

      if (discountFromQuery && typeof discountFromQuery === 'string') {
        redirectURL += `/?discount=${discountFromQuery}`;
      }

      return setupLinkProtocol(redirectURL);
    },

    /**
     * Build the alt text for the brand logo out of the partner name
     */
    altText(): VueI18n.LocaleMessage {
      return this.$t('layouts.logo.alt', { partnerName: this.partnerName })
    },

    /**
     * Checks if login is enabled or not on Launch Darkly.
     */
    loginEnabled(): boolean {
      if (!process.client || !this.$launchDarkly) return false;
      return (
        this.$launchDarkly.variation(
          'WL-FeatureControlPerPartnerEnableMyAccount'
        ) === true
      );
    },

    isBookingPortal() {
      return process.env.NUXT_ENV_IS_BOOKING_PORTAL === 'true';
    },

    /**
     * Returned of the user is logged in or not
     */
    isUserLoggedIn(): boolean {
      //@ts-ignore - cannot find type
      return this.$isLoggedIn;
    },

    /**
     * Login button text is based on if the feature toggle is in, and if the customer is login in or not
     */
    loginButtonText(): VueI18n.TranslateResult {
      return this.loginEnabled && !this.isMigrationSupportInProgress
        ? this.$t('shared.buttons.login')
        : this.$t('UI.tabs.myBookings');
    },

    /**
     * What URL to pass in based on if logging in is enabled and if the user is login or not
     */
    accountUrl(): AccountURL {
      if (!this.loginEnabled) {
        return ACCOUNT_URLS.SEARCH_BOOKING;
      }

      return this.isUserLoggedIn ? ACCOUNT_URLS.HOME : ACCOUNT_URLS.LOGIN;
    },

    showMyBookingsButton(): boolean {
      return this.showLoginFlow ?? !this.isUserLoggedIn;
    },

    menuData(): MenuDataOrUndefined {
      return this.isUserLoggedIn
        ? {
            nickname: this.$auth.user?.['name'] as string,
            logoutHandler: () => this.$auth.logout(),
            makeNewBookingHandler: () =>
              goToApp(
                getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
                ECOMMERCE_URLS.HOME
              ),
          }
        : undefined;
    },

    colourScheme(): ColourScheme {
      const scheme = getAppVariable('colours.header.font');
      if (scheme === COLOUR_SCHEMES.LIGHT || scheme === COLOUR_SCHEMES.DARK) {
        return scheme;
      }
      return COLOUR_SCHEMES.DARK;
    },

    tabsLinks(): TabData['tabsLinks'] {
      return this.tabsData?.tabsLinks || {};
    },
    getRoutePath(): string {
      return this.$route.path || '';
    },
    availableLanguages() {
      const partnerLanguages = getAppVariable('languages') as Locale[];
      if (!partnerLanguages) return [];
      //@ts-ignore `locales` is part of nuxt-i18n, not recognised by typescript
      return this.$i18n.locales.filter((locale: Locale) =>
        partnerLanguages.map((language) => language.code).includes(locale.code)
      );
    },
    heroProduct(): HeroProduct {
      return getAppHeroProduct(APP_HERO_PRODUCTS.PARKING);
    },
    showLanguageSelector(): boolean {
      // TODO: remove EM_430_LOUNGES_LANGUAGE_SELECTOR flag when it's no longer needed
      const isLounges = this.heroProduct === APP_HERO_PRODUCTS.LOUNGES
        && this.$launchDarkly.variation('EM_430_LOUNGES_LANGUAGE_SELECTOR') === true;
      const isParking = this.heroProduct === APP_HERO_PRODUCTS.PARKING;
      const url = window?.location?.href;

      // TODO: remove ECOM_1233_WL_MULTI_LANGUAGE flag when it's no longer needed
      return (
        (isParking || isLounges) &&
        this.$launchDarkly.variation('ECOM_1233_WL_MULTI_LANGUAGE') === true &&
        //@ts-ignore `locales` is part of nuxt-i18n, not recognised by typescript
        this.$i18n.locales.length > 1 &&
        url.includes(getDomainUrl(DOMAINS_NAMES.ECOMMERCE)) &&
        (this.$route.name === ROUTE_NAMES.home || this.$route.name === ROUTE_NAMES.search)
      );
    },

    isMigrationSupportInProgress(): boolean {
      const migrationSupport = getAppVariable('migration_support');
      return migrationSupport?.is_in_progress;
    },
  },

  methods: {
    goToMyBookings(): void {
      //@ts-ignore - cannot find types
      if (this.isMigrationSupportInProgress) {
        const migrationSupport = getAppVariable('migration_support');

        if (migrationSupport?.is_in_progress) {
          window.location.href = migrationSupport.manage_booking_url;
          return;
        }
      }

      //@ts-ignore - cannot find types
      if (!this.isUserLoggedIn && window.location.pathname.includes(ACCOUNT_URLS.CALLBACK)) {
        // redirects to auth0 login page only on callback page
        this.$auth.logout();
      }
      else {
        // finally, redirect to account app
        goToApp(
          getDomainUrl(DOMAINS_NAMES.ACCOUNT),
          //@ts-ignore - cannot find types
          this.accountUrl
        );
      }
    },
  },
});
