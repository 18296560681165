
import { defineComponent } from 'vue';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import type { Locale } from '@white-label-types/internationalization';
import LanguageSelector from '../language-selector/language-selector.vue';
import ModalLanguageSelector from '../modal-language-selector/modal-language-selector.vue';
import { CavuButton } from 'ui-shared-components';

export default defineComponent({
  name: 'LanguageSelectorButton',

  components: {
    CavuButton,
    IconFlag: () => import('@white-label-icon/icon-flag'),
    LanguageSelector,
    ModalLanguageSelector,
  },

  data() {
    return {
      modalOpen: false,
    };
  },

  computed: {
    availableLanguages() {
      const partnerLanguages = getAppVariable('languages') as Locale[];
      if (!partnerLanguages) return [];
      //@ts-ignore `locales` is part of nuxt-i18n, not recognised by typescript
      return this.$i18n.locales.filter((locale: Locale) =>
        partnerLanguages.map((language) => language.code).includes(locale.code)
      );
    },
    mobileDropdownIsOpen() {
      const languageSelector = this.$refs['languageSelector'];
      return languageSelector?.dropdownOpen;
    },
  },

  methods: {
    showLanguageSelectorDropdown(value: boolean): void {
      const languageSelector = this.$refs['languageSelector'];
      if (languageSelector) languageSelector.dropdownOpen = value;
    },
    changeLanguage(language) {
      this.$i18n.setLocale(language.code);
    },
  },
});
