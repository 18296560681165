
// Packages
import {
  defineComponent,
  PropType,
} from 'vue';

// Components
import { BTicketWrap, EntryExitInfo } from 'ui-shared-components';

// Types
import type { BookingDetails } from '@white-label-types/user-details';

export default defineComponent({
  name: 'UpdateDetailsTicketMyAccount',

  components: {
    BTicketWrap,
    EntryExitInfo,
  },

  props: {
    bookingDetails: {
      type: Object as PropType<BookingDetails>,
      required: true,
    },
  },
});
