import { datadogRum } from '@datadog/browser-rum';
import type { GtmInjected } from '@white-label-types/nuxtjs-gtm';

export function trackCheckoutStep(gtm: GtmInjected, step: number, option: string) {
  gtm.push({
    'event': 'checkout',
    'ecommerce': {
      'checkout': {
        'actionField': { 'step': step, 'option': option },
      },
    },
  });
}


export function trackAction(action: string, context: Record<string, string>) {
  datadogRum.addAction(action,
    context
  );
}
