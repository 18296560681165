
// Packages
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { datadogRum } from '@datadog/browser-rum';

// Helpers
import {
  goToApp,
  getDomainUrl,
  ECOMMERCE_URLS,
  DOMAINS_NAMES,
} from '@white-label-helper/switch-app';

// Store
import { useBookingsStore } from '@white-label-store/bookings';

// Mixins
import auth from '@white-label-helper/mixin-auth';

// Components
import Button from '../button/button.vue';
import BookingsPreloader from '../bookings-preloader/bookings-preloader.vue';
import BookingsNoResults from '../bookings-no-results/bookings-no-results.vue';
import BookingsList from '../bookings-list/bookings-list.vue';

export default defineComponent({
  name: 'Bookings',

  components: {
    ControlButton: Button,
    BookingsPreloader: BookingsPreloader,
    BookingsNoResults: BookingsNoResults,
    BookingsList: BookingsList,
  },

  mixins: [auth],

  data() {
    return {
      perPage: 30,
      loadingNextPage: false,
    };
  },

  computed: {
    ...mapState(useBookingsStore, ['bookings']),
    displayNoReservationsMessage(): boolean {
      return !this.bookings.length;
    },
    hideBookingButtons() {
      return this.$launchDarkly?.variation(
        'PREM-856-Aether-Hide-Booking-Buttons'
      );
    },
  },

  async mounted() {
    try {
      this.$global_enableContentPreloader();
      await this.fetchUserBookings(
        {
          bearerToken: this.getToken,
          queryParams: {
            per_page: this.perPage,
          },
        },
      );
    } catch (e: any) {
      datadogRum.addError(new Error(e));
      this.$openModal('GlobalModalError', {
        header: this.$t('shared.modals.errors.technicalIssue.header'),
        body: this.$t('shared.modals.errors.technicalIssue.body'),
        btnText: this.$t('shared.buttons.tryAgain'),
        btnType: 'custom',
      });
    } finally {
      this.$global_disableContentPreloader();
    }
  },

  beforeDestroy() {
    this.$global_disableContentPreloader();
  },

  methods: {
    ...mapActions(useBookingsStore, ['fetchUserBookings', 'fetchUserBookingsNextPage']),
    goToBooking(): void {
      return goToApp(
        getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
        ECOMMERCE_URLS.HOME
      );
    },
    async loadNextPage(): Promise<void> {
      try {
        this.loadingNextPage = true;
        await this.fetchUserBookingsNextPage({
          bearerToken: this.getToken,
          queryParams: { per_page: this.perPage },
        });
      } catch (e: any) {
        datadogRum.addError(new Error(e));
        this.$openModal('GlobalModalError', {
          header: this.$t('shared.modals.errors.technicalIssue.header'),
          body: this.$t('shared.modals.errors.technicalIssue.body'),
          btnText: this.$t('shared.buttons.tryAgain'),
          btnType: 'custom',
        });
      } finally {
        this.loadingNextPage = false;
      }
    },
  },
});
