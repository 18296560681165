
import { defineComponent } from 'vue';
import { formatPrice } from '@white-label-helper/helper-payment';
import { PRODUCT_TYPES } from '@white-label-configuration/constants';

import EntryExitInfo from '../entry-exit-info/entry-exit-info.vue';
import IconWrapper from '../icon-wrapper/icon-wrapper.vue';
import PrivateLoungeInfo from '../b-ticket-private-lounge-details/b-ticket-private-lounge-details.vue';

import IconAlert from '@white-label-icon/icon-alert';
import IconArrowDown from '@white-label-icon/icon-arrow-down';
import IconUser from '@white-label-icon/icon-user';
import IconSuccess from '@white-label-icon/icon-success';
import { TranslateResult } from 'vue-i18n/types';

export default defineComponent({
  name: 'BTicketInfo',
  components: {
    EntryExitInfo,
    IconWrapper,
    IconAlert,
    IconArrowDown,
    IconUser,
    IconSuccess,
    PrivateLoungeInfo,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    totals: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed:{
    isParkingItem (): boolean {
      return this.items[0]?.product_code === PRODUCT_TYPES.PARKING;
    },
    productOption(): string {
      return this.items[0]?.inventory_item?.product_option?.code;
    },
    displayOptions(): { title: TranslateResult, showExitInfo: boolean, showGuests: boolean, showPrivateLoungeFields: boolean } {
      return {
        title: this.isParkingItem
          ? this.$t('search.yourCurrentParkingBooking')
          : this.$t('modifyLounges.currentTitle'),
        showExitInfo: this.isParkingItem,
        showGuests: this.items[0]?.inventory_option?.sub_options?.length > 0,
        showPrivateLoungeFields: this.productOption === 'lounge_private',
      };
    },
    totalPaid(): Intl.NumberFormat | string {
      if(this.totals?.total) {
        return formatPrice(this.totals.total)
      }
      return ''
    }
  },
  methods:{
    formatPrice,
  }
});
