import { formatPrice } from '@white-label-helper/helper-payment';
import { defineStore } from 'pinia';
import { shallowRef } from 'vue';
import { getFeatureFlag } from '@white-label-plugin/launch-darkly';
import { type CartItem } from '@white-label-types/parking-checkout';
import { type ManageBookingParsedOrderItem } from '@white-label-types/parking-booking';

const useDepositStore = defineStore('deposit', () => {
  const cartTotalDepositAmount = shallowRef<null | {
    payNow: string,
    payOnArrival: string
  }>(null);

  const itemsDepositAmount = shallowRef<{
    name: string,
    depositAmount: string,
    arrivalAmount: string,
  }[]>([]);

  const shouldShowDepositInformation = shallowRef(false);

  /**
   * Deposit details for the order
   *
   * @param details - Deposit details object.
   * @param details.pay_now - The amount to be paid upfront.
   * @param details.pay_on_arrival - The amount to be paid on arrival.
   */
  function updateCartDepositTotal({pay_now, pay_on_arrival}: {pay_now: number, pay_on_arrival: number}) {
    // We can use the "pay_on_arrival" to work out if it is a deposit-only product.
    // If the value is greater than 0, then it will be a deposit-only product
    if (getFeatureFlag('gtm-322-deposit-only-products') && pay_on_arrival > 0) {
      // We have a deposit-only item
      cartTotalDepositAmount.value = {
        payNow: formatPrice(pay_now).toString(),
        payOnArrival: formatPrice(pay_on_arrival).toString()
      }
      shouldShowDepositInformation.value = true;
    } else {
      shouldShowDepositInformation.value = false;
      cartTotalDepositAmount.value = null;
    }
  }

  /**
   * Deposit details for each item in the order
   *
   * @param products - An array of CartItem objects representing products.
   */
  function updateItemDeposit(products: Array<CartItem |  ManageBookingParsedOrderItem>) {
    // Only deposit items should have a `product_deposit` greater than 0
    const getDepositItems = products.filter((item) => item.totals.product_deposit > 0);

    if (getFeatureFlag('gtm-322-deposit-only-products') && getDepositItems.length > 0) {
      itemsDepositAmount.value = getDepositItems.map((product) => {
        const productDeposit = product.totals.product_deposit;
        const inventoryItem = product.inventory_item;
        const productName = 'display_name' in inventoryItem ? inventoryItem.display_name : inventoryItem.name;
        shouldShowDepositInformation.value = true;

        return {
          name: productName,
          depositAmount: formatPrice(productDeposit).toString(),
          arrivalAmount: formatPrice(product.totals.total - productDeposit).toString()
        };
      });
    } else {
      itemsDepositAmount.value = []
    }
  }

  return {
    cartTotalDepositAmount,
    itemsDepositAmount,
    updateCartDepositTotal,
    updateItemDeposit,
    shouldShowDepositInformation,
  }
});

export default useDepositStore;
