
import { defineComponent, PropType } from 'vue';

import { ManageBookingState } from '@white-label-types/stores';
import {
  DATE_TIME_FORMATS,
  format,
  parseISO,
} from '@white-label-helper/date-utilities';

import IconWrapper from '../icon-wrapper/icon-wrapper.vue';
import ModalCenter from '../modal-center/modal-center.vue';

export default defineComponent({
  name: 'ModalDifferentDates',

  components: {
    IconAlert: () => import('@white-label-icon/icon-alert'),
    IconWrapper,
    ModalCenter,
  },

  props: {
    newDateTime: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<ManageBookingState['manageBookingState']['items']>,
      required: true,
    },
    primaryProductType: {
      type: String,
      required: true,
    }
  },

  computed: {
    lineItems() {
      const primaryItem = this.items
        .find((item) => item.product_code === this.primaryProductType);

      if (!primaryItem) return [];

      return this.items
        .map((item) => {
          if (item.product_code === this.primaryProductType) {
            return {
              title: this.$t(`UI.modal.differentDates.${item.product_code}.yourNewBooking`),
              dateTime: parseISO(this.newDateTime),
              dateTimeFormatted: this.formatDateTime(this.newDateTime),
              isPrimary: true,
            };
          }

          const title = this.$t(`UI.modal.differentDates.${item.product_code}.yourBooking`)
          const searchCriteria = item.search_criteria[item.product_code];
          const dateTime = `${searchCriteria?.date1} ${searchCriteria?.time1}`

          return {
            title: title,
            dateTime: parseISO(dateTime),
            dateTimeFormatted: this.formatDateTime(dateTime),
            isPrimary: false,
          };
        }).sort((a, b) => {
          // Line item for primary product type should be first
          if (a.isPrimary) return -1;
          if (b.isPrimary) return 1;

          // For other line items, sort by date time
          const dateA = a.dateTime;
          const dateB = b.dateTime;
          return dateA - dateB;
        });
    },
  },

  methods: {
    formatDateTime(dateTimeString: string) {
      const dateTime = parseISO(dateTimeString);
      return format(
        dateTime,
        DATE_TIME_FORMATS.day_month_year_day_time_am_pm_lower_case
      )
    },
    continueAmendment() {
      this.$emit('continue-amendment');
    },
    goBack() {
      this.$emit('go-back');
    },
  },
});
