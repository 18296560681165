
import { defineComponent } from 'vue';

// Components
import DotsLoader from '../dots-loader/dots-loader.vue';
// Helpers
import { isBookingPortal } from '@white-label-helper/is-booking-portal';

export default defineComponent({
  components: {
    DotsLoader,
  },
  props: {
    title: {
      type: String,
      default: '',
    }
  },
  data(){
    return {
      isBookingPortal
    }
  },
  computed:{
    /**
     * Returns the payment form details title
     */
    paymentDetailsTitle() {
      return isBookingPortal ? this.$t('payment.customerDetails') : this.title || this.$t('payment.yourDetails')
    }
  }
});
