
// Packages
import { defineComponent, PropType } from 'vue';

// Types
import type { TranslateResult } from 'vue-i18n';

// Components
import PhoneField from '../phone-field/phone-field.vue';

type PhoneData = {
  /** Phone number without the country code. */
  phone_number: string
  /** Phone number with the country code. */
  formatted_number: string
  /** Country code. */
  country_code: string
  /** Country ISO code */
  country_iso_code: string
}

export default defineComponent({
  name: 'CavuPhone',

  components: {
    PhoneField,
  },

  props: {
    customErrorMessages: {
      type: Object as PropType<Record<string, TranslateResult>>,
    },

    value: {
      type: Object as PropType<PhoneData>,
      required: true,
    },

    vuelidateObj: {
      type: Object, // This should be the object you get from vuelidate
      required: true,
    },
  },

  methods: {
    /**
     * Formats the data from the server into data that the Vue-phone-number-input component can use
     * @param data - The data from the parent element
     * @return - Returns the same data that it received with the only different being that the dialling code is now on
     * ISO format
     */
    formatIncomingData(data: PhoneData): PhoneData {
      return {
        phone_number: data.phone_number,
        formatted_number: data.formatted_number,
        country_code: data.country_code,
        country_iso_code: data.country_iso_code,
      };
    },

    /**
     * Format the data we get from the vue-phone-number-input component to match the data received from the API.
     */
    updatePhone(phoneData: PhoneData) {
      this.$emit('input', {
        phone_number: phoneData.phone_number,
        formatted_number: phoneData.formatted_number,
        country_code: `+${phoneData.country_code}`,
        country_iso_code: phoneData.country_iso_code,
      });
    },
  },
});
