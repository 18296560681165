
import { defineComponent, PropType } from 'vue';
import type { Breakpoints } from '@white-label-types/styles';

export default defineComponent({
  name: 'StickySidebar',

  props: {
    // on what breakpoint change main container flex-direction from 'column' to 'row'
    breakpoint: {
      type: String as PropType<Breakpoints>,
      default: 'lg',
      validator (val: Breakpoints) {
        return ['sm', 'md', 'lg', 'xl'].includes(val);
      },
    },
  },
});
