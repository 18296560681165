
// Packages
import {
  defineComponent,
  PropType,
} from 'vue';

// Types
import type { EntryMethod } from '@white-label-types/parking-booking';

// Constants
import { BARRIER_ACCESS_TYPES } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'BTicketAccessInfo',

  props: {
    accessInfo: {
      type: [Object as PropType<EntryMethod['entryMethod']>, String],
      required: true,
    },

    accessInfoNumber: {
      type: Number,
      required: true,
    },

    licensePlate: {
      type: String,
      default: '',
    },
  },

  computed: {
    formattedAccessType(): string {
      return this.accessInfo.type?.toLowerCase();
    },

    displayImg(): boolean {
      return this.accessInfo.type === BARRIER_ACCESS_TYPES.QRCODE
        || this.accessInfo.type === BARRIER_ACCESS_TYPES.BARCODE;
    },

    displayLicensePlate(): boolean {
      return this.accessInfo.type === BARRIER_ACCESS_TYPES.ANPR;
    },

    displayPinCode(): boolean {
      return this.accessInfo.type === BARRIER_ACCESS_TYPES.PINCODE;
    },
  },
});
