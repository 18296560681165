import { APP_HERO_PRODUCTS, HeroProduct } from '@white-label-configuration/constants'
import { getAppVariable } from '@white-label-helper/get-app-variable';

export function getAppHeroProduct(defaultValue: HeroProduct): HeroProduct {
  const envHeroProduct: string | undefined = process.env['NUXT_ENV_DEFAULT_HERO_PRODUCT'];
  const appHeroProduct: string | undefined = getAppVariable('primary_product_type');
  const heroProduct: string | undefined = envHeroProduct || appHeroProduct;

  if (appHeroProduct === undefined) {
    if (envHeroProduct === undefined) {
      throw new ReferenceError('The "NUXT_ENV_DEFAULT_HERO_PRODUCT" environment variables must be set where config primary_product_type is missing')
    }
    throw new ReferenceError('The "primary_product_type" property is missing from app config');
  }

  if (heroProduct === '') {
    throw new ReferenceError('The hero product config/environment variable must be a filled string');
  }

  const heroProductRecognised: boolean = Object.values(APP_HERO_PRODUCTS).includes(heroProduct as HeroProduct)

  if (heroProductRecognised) {
    return heroProduct as HeroProduct
  }

  // Warn and return the default value if not set
  console.warn(`You have not set a default hero product to fall back to`);
  return defaultValue
}
