import type { ParsedPagination, JSONApiLinks, JSONApiMeta } from '@white-label-types/api';

/**
 * Parses the `meta` and `links` objects from a JSON API enabled endpoint response into
 * values used by FE to control pagination.
 * https://jsonapi.org/format/
 * @param payload the `meta` and `links` objects from a JSON API endpoint response.
 * @returns parsed pagination info.
 */
export function parseJSONApiPagination(payload: { meta: JSONApiMeta, links: JSONApiLinks }): ParsedPagination {
  const { meta, links } = payload;
  return {
    perPage: meta.per_page,
    currentPage: meta.current_page,
    hasNextPage: links.next !== null,
    hasPreviousPage: links.prev !== null,
    from: meta.from,
    to: meta.to,
    totalCount: meta.total,
    links: {
      first: links.first,
      last: links.last,
      next: links.next,
      prev: links.prev,
    },
  };
}
