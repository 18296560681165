
import { defineComponent } from 'vue';
import { PortalTarget } from 'portal-vue';

// Components
import ToggleContent from '../toggle-content/toggle-content.vue'

export default defineComponent({
  name: 'BTicketPaymentSummary',

  components: {
    PortalTarget,
    ToggleContent,
  },

  props: {
    showForPrint: {
      type: Boolean,
      default: false,
    }
  }
});
