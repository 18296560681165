import contentPreloader from './store/content-preloader';
var namespace = 'contentPreloader';
export var contentPreloaderModule = function contentPreloaderModule(context, inject) {
  context.store.registerModule(namespace, contentPreloader({
    namespace: namespace
  }), {
    // if the store module already exists, preserve it
    preserveState: Boolean(context.store.state[namespace])
  });
  function globalSetContentPreloader(state) {
    context.store.commit('contentPreloader/updateStatus', state);
  }
  inject('global_enableContentPreloader', function () {
    return globalSetContentPreloader(true);
  });
  inject('global_disableContentPreloader', function () {
    return globalSetContentPreloader(false);
  });
  context.global_enableContentPreloader = function () {
    return globalSetContentPreloader(true);
  };
  context.global_disableContentPreloader = function () {
    return globalSetContentPreloader(false);
  };
};