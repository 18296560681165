
// Types
import type { Partners } from '@white-label-types/partners';

// Constants
import { PAYMENT_PROVIDER_COLORS, PAYMENT_PROVIDERS } from '@white-label-configuration/constants';

// Packages
import { defineComponent, PropType } from 'vue';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';

export default defineComponent({
  props: {
    removeButton: {
      default: true,
      type: Boolean,
    },

    amount: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    },

    paymentsConfig: {
      type: Object,
      required: true,
    },

    paymentIntentUpdated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stripeConfig(): string {
      const newPaymentOnlyStrategy = !!process.env.NUXT_ENV_NEW_PAYMENT_ONLY_STRATEGY
          && process.env.NUXT_ENV_PAYMENT_PROVIDER === PAYMENT_PROVIDERS.STRIPE;

      return JSON.stringify({
        ...this.paymentsConfig,
        stripeHandlePaymentIntent: newPaymentOnlyStrategy,
      });
    },

    stripeStyles(): string {
      return JSON.stringify({
        colorPrimary: PAYMENT_PROVIDER_COLORS.STRIPE.PRIMARY,
        colorBackground: PAYMENT_PROVIDER_COLORS.STRIPE.BACKGROUND,
        colorText: PAYMENT_PROVIDER_COLORS.STRIPE.TEXT,
        colorDanger: PAYMENT_PROVIDER_COLORS.STRIPE.DANGER,
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '4px',
        borderRadius: '0.5rem',
      });
    },

    currency(): Partners['default_currency'] {
      return getAppVariable('default_currency');
    },
  },

  methods: {
    handlePayload(event: any) {
      this.$emit('payment-completed', {
        payment_method_id: event.detail.nonce,
        merchant_id: process.env.NUXT_ENV_PAYMENT_MERCHANT_ID,
      });
    },

    handleError() {
      this.$emit('error');
    },

    handleStripePaymentIntentPayload() {
      this.$emit('stripe-payment-intent-payload', {
        payment_method_id: null,
        merchant_id: process.env.NUXT_ENV_PAYMENT_MERCHANT_ID,
      });
    },

    submitForm() {
      // This button is located in the `stripe-ui > stripe-hosted-fields` element
      const btn: HTMLButtonElement | null = document.querySelector('#submit');
      btn?.click();
    },
  },
});
