import Vue from 'vue';
import type { ModalData } from '@white-label-types/component';

Vue.mixin({
  methods: {
    $openModal(modalName: string, modalData: ModalData): Promise<unknown> {
      this.$global_eventEmitter.$emit('setModalData', {
        component: modalName,
        data: modalData,
      });

      return new Promise((resolve, reject) => {
        function isCustomEvent(event: Event): event is CustomEvent {
          return 'detail' in event;
        }
        const callback = (event: Event) => {
          if (isCustomEvent(event)) {
            resolve(event.detail);
          } else {
            reject(new Error('No details provided'));
          }

          window.removeEventListener('modalClose', callback);
        };

        window.addEventListener('modalClose', callback);
      });
    },
  },
});
