
// Packages
import { defineComponent } from 'vue';

// Components
import IconPrinter from '@white-label-icon/icon-printer';

// Helpers
import { print } from '@white-label-helper/print';

export default defineComponent({
  name: 'PrintButton',
  components: {
    IconPrinter
  },

  props: {
    id: {
      type: String,
      default: '',
    },
  },

  methods: {
    printReceipt () {
      print();
    }
  }
});
