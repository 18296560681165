import type { AxiosInstance } from 'axios';
import type { Interceptors, Options } from './axios-config';
import { HTTP_STATUS_CODES } from '@white-label-configuration/constants';

type BaseInstance = {
  interceptors: Interceptors;
  options: Options;
};

/*
* This is main Axios instance config.
* Settings from this file are inherited for http services with and without auth.
*  */
const baseInstance = Object.freeze<BaseInstance>({
  interceptors: {
    onRequest(this: AxiosInstance, fn) {
      // @ts-expect-error - An expression of type 'void' cannot be tested for truthiness.
      this.interceptors.request.use(config => fn(config) || config);
    },
    onResponse(this: AxiosInstance, fn) {
      // @ts-expect-error - An expression of type 'void' cannot be tested for truthiness.
      this.interceptors.response.use(response => fn(response) || response);
    },
    onRequestError(this: AxiosInstance, fn) {
      this.interceptors.request.use(undefined, error => fn(error) || Promise.reject(error));
    },
    onResponseError(this: AxiosInstance, fn) {
      this.interceptors.response.use(undefined, error => fn(error) || Promise.reject(error));
    },
  },
  options: {
    responseType: 'text',
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${process.env['NUXT_ENV_AFFILIATE_APPLICATION_TOKEN']}`,
    },
    validateStatus(status: number) {
      return status >= HTTP_STATUS_CODES.OK && status < HTTP_STATUS_CODES.MULTIPLE_CHOICE;
    },
    transformResponse(data: undefined | string): void | null | unknown {
      if (!data) { return; }
      const responseWithHtml = data.match(/DOCTYPE html/gi);

      if (responseWithHtml) { return null; }

      return JSON.parse(data);
    },
  },
});

export default baseInstance;
