import Vue from 'vue';
import { SCREENS } from '@white-label-configuration/constants';

const screenSizeGlobal = Vue.observable({ size: 0 });

Vue.mixin({
  computed: {
    screenSize(): number {
        return screenSizeGlobal.size;
    },

    isMobileResolution(): boolean {
        return (this.screenSize as number) < SCREENS.MD;
    },

    isTabletResolution(): boolean {
        return SCREENS.MD <= (this.screenSize as number) && (this.screenSize as number) < SCREENS.LG;
    },

    isDesktopResolution(): boolean {
        return (this.screenSize as number) >= SCREENS.LG;
    },

    isLargeDesktopResolution(): boolean {
        return (this.screenSize as number) >= SCREENS.XL;
    },
  },
});

function setNewScreenSize() {
  screenSizeGlobal.size = window.innerWidth;
}

/**
 * @typedef {object} process
 * @property {boolean} client
 */
if (process.client) {
  setNewScreenSize();
  window.addEventListener('resize', setNewScreenSize);
}
