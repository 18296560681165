import {
  parseISO,
  add,
  differenceInDays,
  startOfToday,
  format,
  DATE_TIME_FORMATS,
} from '@white-label-helper/date-utilities';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import type { LoungesSearchCriteria } from '@white-label-types/product-types';

type GaDataLayer = {
  /** Whether the user is logged in or not, we should be able to get this from auth0 this.$auth.state.loggedIn */
  loginStatus: boolean;
  /** The user's ID. There isn't a clear ID with auth0 but the user.sub is the closest atm this.$auth.state.user.sub */
  userId: string | null;
  /** The airport name */
  airportName: string | null;
  /** The airport code */
  airportCode: string;
  /** The airport terminal number or "dontKnow" */
  airportTerminalId?: string;
  /** What the service is (e.g. "parking") */
  serviceType: string;
  /** How far in advance the booking was made */
  daysAheadOfBooking: number | null;
  /** The date of departure */
  parkingDepartureDate?: string;
  /** The time of departure */
  parkingDepartureTime?: string;
  /** The date of return */
  parkingReturnDate?: string;
  /** The time of return */
  parkingReturnTime?: string;
  /** The duration between departure and return */
  stayDuration?: number;
  /** The order reference */
  orderReference?: string;
  /** The coupon or voucher code */
  couponVoucher?: string;
  /** The name of the partner */
  partnerName: string;
  /** The language of the site */
  siteLanguage: string;
  /** The parking name */
  carParkName?: string;
  /** The Lounge Departure date */
  loungeDepartureDate?: string;
  /** The Lounge Departure time */
  loungeDepartureTime?: string;
  /** The Lounge Return date */
  loungeReturnDate?: string;
  /** The Lounge Return time */
  loungeReturnTime?: string;
  /** The Airline code */
  airLine?: string | null;
  /** Terminal ID */
  terminalId?: string | null;
  /** The customers chosen departure date */
  flightDepartureDate?: string | null;
  /** The customers chosen flight departure time */
  flightDepartureTime?: string | null;
  /** Baggage type */
  baggage?: string;
  /** Requested for tracking but currently not sure what to use */
  userType?: string | null;
  /** Lounge Name, attached if the product has one */
  loungeName?: string | null;
  /** Lounge entry date */
  entryDate?: string | null;
  /** Lounge entry time */
  entryTime?: string | null;
  /** Number of guests */
  numberOfGuest?: number | null;
  /** Number of adults and seniors */
  adult?: number;
  /** Number of Children and Infants */
  children?: number;
  /** GA event name */
  event?: string;
  /** The users selected timeslot */
  timeslot?: string | null;
};

/** The data object to send to GA */
const gaLayer: GaDataLayer = {
  loginStatus: false,
  userId: '',
  airportName: '',
  airportCode: '',
  airportTerminalId: '',
  serviceType: 'parking',
  daysAheadOfBooking: 0,
  parkingDepartureDate: '',
  parkingDepartureTime: '',
  parkingReturnDate: '',
  parkingReturnTime: '',
  stayDuration: 0,
  orderReference: '',
  couponVoucher: '',
  partnerName: '',
  siteLanguage: 'en-US',
  carParkName: '',
};

type Options = {
  loginStatus?: boolean;
  airportName?: string;
  airportCode?: string;
  airportTerminalId?: string;
  parkingDepartureDate?: string;
  parkingDepartureTime?: string;
  parkingReturnDate?: string;
  parkingReturnTime?: string;
  orderReference?: string;
  couponVoucher?: string;
  partnerName?: string;
  siteLanguage?: string;
  carParkName?: string;
  event?: string;
};

/**
 * Set and fire the GA dataLayer
 * @param options - The options to update the data with
 * @returns The dataLayer object
 */

export const gaDataLayer = (options: Options) => {
  const {
    loginStatus = gaLayer.loginStatus,
    airportName = gaLayer.airportName,
    airportCode = gaLayer.airportCode,
    airportTerminalId = gaLayer.airportTerminalId,
    parkingDepartureDate = gaLayer.parkingDepartureDate,
    parkingDepartureTime = gaLayer.parkingDepartureTime,
    parkingReturnDate = gaLayer.parkingReturnDate,
    parkingReturnTime = gaLayer.parkingReturnTime,
    orderReference = gaLayer.orderReference,
    couponVoucher = gaLayer.couponVoucher,
    partnerName = gaLayer.partnerName,
    siteLanguage = gaLayer.siteLanguage,
    carParkName = gaLayer.carParkName,
  } = options;

  if (parkingDepartureDate) {
    gaLayer.daysAheadOfBooking = differenceInDays(
      parseISO(parkingDepartureDate),
      startOfToday()
    );
  }

  if (parkingDepartureDate && parkingReturnDate) {
    const arrival = parseISO(parkingReturnDate);
    const departure = parseISO(parkingDepartureDate);
    const addOneDay = add(arrival, { days: 1 });
    // Adding 1 day to arrival to make sure it's included in the calculation
    const days = (+addOneDay - +departure) / 864e5; // Days in milliseconds
    // The min value is 1
    gaLayer.stayDuration = Math.round(days) || 1;
  }

  if (options.event) {
    gaLayer.event = options.event;
  }

  gaLayer.loginStatus = loginStatus;
  gaLayer.airportCode = airportCode;
  gaLayer.airportTerminalId = airportTerminalId;
  gaLayer.parkingDepartureDate = parkingDepartureDate;
  gaLayer.parkingDepartureTime = parkingDepartureTime;
  gaLayer.parkingReturnDate = parkingReturnDate;
  gaLayer.parkingReturnTime = parkingReturnTime;
  gaLayer.orderReference = orderReference;
  gaLayer.couponVoucher = couponVoucher;
  gaLayer.partnerName = partnerName;
  gaLayer.siteLanguage = siteLanguage;
  gaLayer.carParkName = carParkName;
  gaLayer.airportName = airportName;

  return gaLayer;
};

export function gaDataLayerLounges(
  formData: LoungesSearchCriteria['lounges'],
  extend = {}
) {
  const entryDate = formData.date1 || formData.date || '';
  const groups = formData.groups || {};
  const data: GaDataLayer = {
    airportCode: getAppVariable('poi.code'),
    loungeDepartureDate: formData?.date || '',
    loungeDepartureTime: formData?.time || '',
    loungeReturnDate:  formData?.date2 || '',
    loungeReturnTime: formData?.time2 || '',
    terminalId: `${formData?.terminal || 'dontKnow'}`,
    airLine: formData?.airline || '',
    baggage: formData?.baggage_type || '',
    userType: '',
    partnerName: getAppVariable('partner_name'),
    loungeName: '',
    serviceType: 'lounges',
    daysAheadOfBooking: differenceInDays(parseISO(entryDate), startOfToday()),
    entryDate: entryDate
      ? format(parseISO(entryDate), DATE_TIME_FORMATS.year_month_day)
      : '',
    entryTime: formData.time1 || formData.time || '',
    numberOfGuest: Object.values(groups).reduce((acc, num) => acc + num, 0),
    adult: (groups['adult'] || 0) + (groups['senior'] || 0),
    children: (groups['child'] || 0) + (groups['infant'] || 0),
    loginStatus: false,
    userId: '',
    siteLanguage: getAppVariable('default_language'),
    ...extend,
  };
  return data;
}

/**
 * Values that are used at all steps of the journey
 * @returns The dataLayer object
 */
export const gaDataLayerTimeslotCommonValues: GaDataLayer = {
  airportCode: '',
  terminalId: null,
  airLine: null,
  userType: null,
  partnerName: '',
  serviceType: 'securityFastTrack',
  daysAheadOfBooking: null,
  numberOfGuest: null,
  loginStatus: false,
  userId: null,
};

/**
 * Values that are used only in step 1 of the journey
 * @returns The dataLayer object
 */
export const gaDataLayerTimeslotStep1Values: Partial<GaDataLayer> = {
  siteLanguage: '',
  timeslot: null,
  flightDepartureDate: null,
  flightDepartureTime: null,
};

/**
 * Values that are used only in step 2 of the journey
 * @returns The dataLayer object
 */
export const gaDataLayerTimeslotStep2Values: Partial<GaDataLayer> = {
  loungeName: null,
  entryDate: null,
  entryTime: null,
  adult: 0,
  children: 0,
};

export const gaDataLayerTimeslotStep1InitialValues: GaDataLayer = { ...gaDataLayerTimeslotCommonValues, ...gaDataLayerTimeslotStep1Values };
export const gaDataLayerTimeslotStep2InitialValues: GaDataLayer = { ...gaDataLayerTimeslotCommonValues, ...gaDataLayerTimeslotStep2Values };

/**
 * Create the data layer object in step 1 of the journey
 * @param formData Values that are subject to change at this stage of the journey
 * @returns The dataLayer object
 */
export function gaDataLayerTimeslotHome(
  formData: {
    terminal?: string;
    numberOfGuest?: number;
    flightDepartureDate?: string | null;
    airline?: string;
    flightDepartureTime?: string;
  },
): GaDataLayer {
  const result = gaDataLayerTimeslotStep1InitialValues;

  if (formData.flightDepartureDate) {
    result.daysAheadOfBooking = differenceInDays(parseISO(formData.flightDepartureDate), startOfToday());
  }
  result.terminalId = formData.terminal ?? result.terminalId;
  result.numberOfGuest = formData.numberOfGuest ?? result.numberOfGuest;
  result.flightDepartureDate = formData.flightDepartureDate ?? result.flightDepartureDate;
  result.airLine = formData.airline ?? result.airLine;
  result.flightDepartureTime = formData.flightDepartureTime ?? result.flightDepartureTime;
  result.airportCode = getAppVariable('poi.code');
  result.partnerName = getAppVariable('partner_name');
  result.siteLanguage = getAppVariable('default_language');

  return result;
};

// For step 2
export function gaDataLayerTimeslot(
  formData: {
    terminal?: string;
    passengers?: number;
    departureDate?: string | null;
    airline?: string;
    flightDepartureTime?: string;
  },
): GaDataLayer {
  const result = gaDataLayerTimeslotStep2InitialValues;

  if (formData.departureDate) {
    result.daysAheadOfBooking = differenceInDays(parseISO(formData.departureDate), startOfToday());
  }

  result.terminalId = formData.terminal ?? result.terminalId;
  result.numberOfGuest = formData.passengers ?? result.numberOfGuest;
  result.entryDate = formData.departureDate ?? result.entryDate;
  result.airLine = formData.airline ?? result.airLine;
  result.flightDepartureTime = formData.flightDepartureTime ?? result.flightDepartureTime;
  result.adult = formData.passengers ?? result.adult;
  result.airportCode = getAppVariable('poi.code');
  result.partnerName = getAppVariable('partner_name');

  return result;
}
