
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'IconSettings',
  props: {
    width: {
      type: [String, Number] as PropType<string | number>,
      default: 20,
    },
    height: {
      type: [String, Number] as PropType<string | number>,
      default: 22,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
});
