
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BTicketExtras',
  props: {
    selectedOption: {
      type: Object,
      default() {
        return {};
      },
    },
  },
});
