type State = {
  options: object,
  enabled: boolean,
};

export default (options: object) => ({
  namespaced: true,

  state: (): State => ({
    options,
    enabled: false,
  }),

  mutations: {
    updateStatus(state: State, payload: boolean) {
      state.enabled = payload;
    },
  },
});
