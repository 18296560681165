
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'IconSuccess',
  props: {
    width: {
      type: [String, Number] as PropType<string | number>,
      default: 18,
    },
    height: {
      type: [String, Number] as PropType<string | number>,
      default: 13,
    },
    color: {
      type: String,
      default: '#90C652',
    },
  },
});
