
import { defineComponent, PropType } from 'vue';
import type { DropdownItems } from '@white-label-types/dropdown';
import IconButton from '@white-label-icon/icon-button';

export default defineComponent({
  name: 'DropdownList',
  components: {
    IconButton,
  },
  props: {
    value: {
      type: String,
      required: true,
    },

    items: {
      type: Array as PropType<DropdownItems>,
      required: true,
    },

    dropdownOpened: {
      type: Boolean,
      default: false,
    },

    mobilePopUpTitle: {
      type: String,
      required: false,
      default: '',
    },

    matchWidth: {
      type: Boolean,
      default: false,
    },
  },
});
