
export default {
  name: 'SummaryProductItem',

  props: {
    name: {
      type: String,
      default: '',
    },
    price: {
      type: [String, Number, Intl.NumberFormat],
      default: '',
    },
    isDiscount: {
      type: Boolean,
      default: false,
    },
  },
};
