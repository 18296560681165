// Packages
import { datadogRum } from '@datadog/browser-rum';

// Types
import type { CartRevalidationData } from '@white-label-types/pusher';
import type { default as Pusher } from 'pusher-js';

// Constants
import { pusherErrorTimeout } from '@white-label-configuration/constants';

// Helpers
import { revalidateCartRequest } from '@white-label-helper/api-parkings-cart';
import getPusherChannel from '@white-label-helper/pusher-channel';

let channel: Pusher;

export function revalidateCartHelper() {
  let channelName: string;

  function destroyChannelListener() {
    if (channel === undefined) {
      channel = getPusherChannel();
    }
    channel.unsubscribe(channelName);
  }

  function revalidateCart(cartToken: string) {
    return new Promise<CartRevalidationData>((resolve, reject) => {
      channelName = `cart.${cartToken}`;

      if (channel === undefined) {
        channel = getPusherChannel();
      }

      const localPusher = channel.subscribe(channelName);

      revalidateCartRequest(cartToken).catch((error: unknown) => {
        reject(error);
      });

      localPusher.bind('CartRevalidationCompletedEvent', (data: CartRevalidationData) => {
        resolve(data);
      });

      localPusher.bind('error', (error: unknown) => {
        reject(error);
      });

      // Default timeout to reject request if it's frozen
      setTimeout(() => {
        const oneSecond = 1000;
        reject(new Error(`Timed out after ${pusherErrorTimeout / oneSecond} seconds`));
      }, pusherErrorTimeout);
    })
      .catch((e) => {
        datadogRum.addError(`pusher-revalidate-cart: ${e}`);
        throw e;
      })
  }

  return {
    revalidateCart,
    destroyChannelListener,
  };
}
