
import { defineComponent } from 'vue';
import IconCheck from '@white-label-icon/icon-check';
import IconClose from '@white-label-icon/icon-close';

export default defineComponent({
  name: 'Notification',

  components: {
    IconCheck,
    IconClose,
  },

  props: {
    message: {
      required: true,
      type: String,
    },
  },
});
