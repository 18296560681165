// Types
import type { User } from '@white-label-types/parking-shared';
import type { AxiosRequestConfig } from 'axios';
import { JSONApiResponse } from '@white-label-types/api';
import { Order } from '@white-label-types/account-booking';

// Parsers
import { parserGetUserData } from '@white-label-helper/get-user-data-parser';

// Helpers
import { httpBooking } from '@white-label-configuration/http';

import { isBookingPortal } from '@white-label-helper/is-booking-portal';
import { myApp } from '@white-label-plugin/global-app-context';
import { datadogRum } from '@datadog/browser-rum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getBookingsForUser = (bearerToken: string, queryParams?: Record<string, any>): Promise<JSONApiResponse<Order[]>> => {
  return httpBooking.get(
    `account/orders/`,
    {
      headers: { Authorization: bearerToken },
      query: queryParams,
    }
  )
    .then((res) => res.body)
};

// @ts-expect-error - error here
httpBooking.onRequest((config) => {
  if (isBookingPortal) {
    // @ts-expect-error - no param
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${myApp.$auth?.user['https://user_metadata/channel_token']}`;
  }
  config.headers['X-Datadog-Session-ID'] = datadogRum.getInternalContext()?.session_id || null;
});

export interface BookingOrderBody {
  cart_token: string;
  user: User;
  gateway_data?: {
    payment_method_id: string;
    merchant_id: string;
  };
  booker_email?: string | null;
  customer_email?: string | null;
  others_email?: string | null;
  send_distribution_api_emails: boolean
}

export interface UpdateBookingOrderBody extends BookingOrderBody {
  original_order_item_id: number;
}

export interface ContinueBookingBody {
  status: string;
  forward: {
    saga_id: string;
    saga_message_id: string;
  }
}

interface DifferenceAfterAmendmentBody {
  originalOrderItemId: string;
  cartItemTotal: number;
  cartItemProductDeposit: number
}

export interface DifferenceAfterAmendmentResponse {
  amendment_fee: number;
  amount_paid_difference: number;
  original_order_item_total: number;
  cart_item_total: number;
  difference: number;
  original_order_item?: { [key: string]: unknown };
  original_order_item_product_deposit: number
}
// @ts-expect-error property
httpBooking.onRequest((config) => {
  const session_id = datadogRum.getInternalContext()?.session_id || null;
  config.headers['X-Datadog-Session-ID'] = session_id;
});

export const handleBookingWithoutPayment = (body: BookingOrderBody): Promise<AxiosRequestConfig> => (
  httpBooking.post('booking/distribution/order', { body })
);
export const handleAmendBookingWithoutPayment = (body: BookingOrderBody): Promise<AxiosRequestConfig> => (
  httpBooking.put('booking/distribution/order', { body })
);

export const handlePaymentRequestOriginal = (body: BookingOrderBody): Promise<AxiosRequestConfig> => (
  httpBooking.post('booking/order', { body })
);

export const handlePaymentRequestManageBooking = (body: UpdateBookingOrderBody): Promise<AxiosRequestConfig> => (
  httpBooking.put('booking/order', { body })
);

export const continueBookingRequest = (body: ContinueBookingBody): Promise<AxiosRequestConfig> => (
  httpBooking.post('booking/order/continue', { body })
);

export const getUserData = (piiToken: string): Promise<ReturnType<typeof parserGetUserData>> => (
  httpBooking.get(`pii-data/${piiToken}`)
    .then(({ body }) => parserGetUserData(body.data))
);

export const differenceAfterAmendment = (
  manageBookingToken: string,
  { originalOrderItemId, cartItemTotal, cartItemProductDeposit }: DifferenceAfterAmendmentBody,
): Promise<DifferenceAfterAmendmentResponse> => (
  httpBooking.post('/booking/order/item/difference-after-amendment', {
    headers: { 'Management-Token': manageBookingToken },
    body: {
      original_order_item_id: originalOrderItemId,
      cart_item_total: cartItemTotal,
      cart_item_product_deposit: cartItemProductDeposit,
    },
  }).then((response) => response?.body)
);
