
import { defineComponent } from 'vue';

// Components
import { DotsLoader } from 'ui-shared-components';

export default defineComponent({
  components: {
    DotsLoader,
  },

  props: {
    message: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    show(): boolean {
      return !!this.message;
    },
  },
});
