

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductSummaryExtrasInfo',
  props: {
    product: {
      type: Object,
      required: true
    },
  },
  computed: {
    optionName() {
    return this.product?.inventory_option?.sub_options[0].details.name_formatted;
    }
  }
});

