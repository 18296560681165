

/**
 * This is @deprecated and will be removed in a follow up PR, please use ui-shared-components version.
 */

import { defineComponent, PropType } from 'vue';
import type VueI18n from 'vue-i18n';


type Item = {
  value: string;
}

type CustomErrorMessages = {
  [key: string]: string
}

type InputMode = 'text' | 'tel' | 'email'

export default defineComponent({
  name: 'TextDropdown',
  components: {
    IconArrowDown: () => import('@white-label-icon/icon-arrow-down'),
  },

  props: {
    items: {
      type: Array as PropType<Item[]>,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    inputMode: {
      type: String as PropType<InputMode>,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Object as PropType<Vue['error']>,
      default: () => ({}),
    },
    customErrorMessages: {
      type: Object as PropType<CustomErrorMessages>,
      default: () => ({}),
    },
    addRequiredStar: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      searchQuery: '',
      selectedItem: '',
      open: false,
    };
  },

  computed: {
    errorExists (): boolean {
      return (this.error && this.error.$error);
    },

    errorMessage (): VueI18n.TranslateResult | string {
      if (this.customErrorMessage) { return this.customErrorMessage; }
      if (!this.error.required) {
        return `Please enter ${this.label.toLowerCase()}`;
      }

      return `Please enter a valid ${this.label.toLowerCase()}`;
    },

    customErrorMessage (): string {
      const errorState: string = this.getErrorState();
      return this.getErrorMessage(errorState);
    },
  },

  methods: {
    onClick (): void {
      this.openDropdown();
    },

    onInput (e: Event): void {
      const value = (e.target as HTMLInputElement).value;
      this.$emit('input', value);

      // Close dropdown when user is typing
      this.open = false;
    },

    onFocus (): void {
      this.$emit('focus');
    },

    onBlur (): void {
      this.$emit('blur');
    },

    openDropdown (): void {
      if (!this.items) { return; }
      this.open = true;
    },

    closeDropdown (): void {
      this.open = false;
    },

    itemSelected (item: Item): void {
      this.closeDropdown();
      this.$emit('input', item.value);
    },

    getErrorState (): string {
      if (!this.error.$error) { return ''; }
      const validationFields = Object.entries(this.error).filter(i => i[0][0] !== '$');
      const foundField = validationFields.find(([, value]) => !value);
      return foundField ? foundField[0] : '';
    },

    getErrorMessage (errorName: string): string {
      if (!errorName || !this.customErrorMessages) { return ''; }
      return this.customErrorMessages[errorName];
    },
  },
});
