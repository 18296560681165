
import { defineComponent, PropType } from 'vue';
import type { PurchaseProduct } from '@white-label-types/parking-booking';

import AdditionalInfoPreloader from '../additional-info-preloader/additional-info-preloader.vue';
import TicketPreloader from '../ticket-preloader/ticket-preloader.vue';
import ManageBookingReceiptLounges from '../manage-booking-receipt-lounges/manage-booking-receipt-lounges.vue';

export default defineComponent({
  name: 'ManageBookingOrderItems',

  components: {
    ManageBookingReceiptLounges,
    TicketPreloader,
    AdditionalInfoPreloader,
  },

  props: {
    hideAccessSection: {
      type: Boolean,
    },

    hideLocationInfo: {
      type: Boolean,
    },

    displayCancellationPolicy: {
      type: Boolean,
      default: true,
    },

    items: {
      type: Array as PropType<PurchaseProduct[]>,
      required: true,
    },

    contentLoading: {
      type: Boolean,
    },

    orderReference: {
      type: String,
      default: '',
    },
  },
});
