
import { defineComponent, PropType } from 'vue'
export default defineComponent({
  name: 'IconAir',
  props: {
    width: {
      type: [String, Number] as PropType<string | number>,
      default: 18
    },
    height: {
      type: [String, Number] as PropType<string | number>,
      default: 18
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
})
