
import { defineComponent, PropType } from 'vue';

interface Tab {
  key: string,
  text: string
}

export default defineComponent({
  name: 'TabsController',

  props: {
    value: {
      type: String,
      required: true,
    },

    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
  },

  methods: {
    selectTab (tab: Tab) {
      this.$emit('input', tab.key);
    },
  },
});
