
// Packages
import { PropType, defineComponent } from 'vue';

// Types
import type { TranslateResult } from 'vue-i18n';

// Constants
import { NAMED_ROUTES } from '@white-label-configuration/constants';

// Store
import {
  readIsCancelled,
  commitClearManageBookingState,
  readUserEmail,
} from '@white-label-store/manage-booking';

// Mixins
import auth from '@white-label-helper/mixin-auth';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';

import ManageBookingNavigation from '../manage-booking-navigation/manage-booking-navigation.vue';
import ReceiptInfoHeader from '../receipt-info-header/receipt-info-header.vue';
import BackButton from '../back-button/back-button.vue';
import { ManageBookingState } from '@white-label-types/stores';
import GuestHint from '../guest-hint/guest-hint.vue';

type ActionType = {
  path: (typeof NAMED_ROUTES.manage_booking)[keyof typeof NAMED_ROUTES.manage_booking];
  actionType: 'route';
};

export default defineComponent({
  name: 'ManageBookingHeader',

  components: {
    GuestHint,
    BackButton: BackButton,
    MBNavigation: ManageBookingNavigation,
    ReceiptInfoHeader: ReceiptInfoHeader,
  },

  mixins: [auth],

  props: {
    orderData: {
      type: Object as PropType<ManageBookingState['manageBookingState']>,
      required: false,
      default: null,
    },
  },

  computed: {
    getUserEmail(): ReturnType<typeof readUserEmail> {
      return readUserEmail(this.$store);
    },

    title(): TranslateResult {
      return this.$t('updateDetails.successTitle');
    },

    subtitle(): TranslateResult {
      return this.$t('updateDetails.successSubtitle', {
        email: this.getUserEmail,
      });
    },

    bookingUpdatedTitle(): TranslateResult {
      return this.$t('manageBooking.titleManageBooking');
    },

    bookingUpdatedSubtitle(): TranslateResult {
      return this.$t('manageBooking.subtitleManageBooking', {
        email: this.getUserEmail,
      });
    },

    isCancelled(): boolean {
      return readIsCancelled(this.$store);
    },

    showPersonalDetailsSuccess(): boolean {
      return (
        this.$route && this.$route.query['personalDetailsSuccess'] === 'true'
      );
    },

    showUpdateBookingSuccess(): boolean {
      return (
        this.$route && this.$route.query['updateBookingSuccess'] === 'true'
      );
    },

    backButtonText(): TranslateResult {
      return this.isAuth0UserLoggedIn
        ? this.$t('shared.buttons.myBookings')
        : this.$t('shared.buttons.searchForBooking');
    },

    addGuestDetailsEnabled(): boolean {
      return this.$launchDarkly.variation(
        'PREM-797-Aether-Payment-Summary-Add-Guest-Details'
      );
    },
  },
  beforeUnmount() {
    commitClearManageBookingState(this.$store);
  },
  methods: {
    emitActionChecker(actionObject: ActionType): void {
      this.$emit('check-paypal-policy', actionObject);
    },

    handleGoBack(): void {
      const migrationSupport = getAppVariable('migration_support');
      if (migrationSupport?.is_in_progress) {
        window.location.href = migrationSupport.manage_booking_url;
        return;
      }

      this.$cookies.remove('manageBookingToken', {
        path: '/',
        domain: process.env.NUXT_ENV_DOMAIN,
      });

      // As `managementBookingToken` has been removed, use `replace`
      // to prevent users from returning here without a valid management token.
      this.isAuth0UserLoggedIn
        ? this.$router.replace(NAMED_ROUTES.home)
        : this.$router.replace({
            path: NAMED_ROUTES.manage_booking.search,
            query: { action: 'search' },
          });
    },
  },
});
