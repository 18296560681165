import defaultsDeep from 'lodash/defaultsDeep';
import { Options } from './axios-config';
import BaseConfig from './http.base-instance-config';
import { getAppVariable } from '@white-label-helper/get-app-variable';

const { interceptors } = BaseConfig;
const baseURL = `${getAppVariable('regional_config').domains.cart}/${getAppVariable('api_version')}`;

interface CartInstance extends Options {
  baseURL: string | undefined;
}

const options: CartInstance = defaultsDeep(
  {
    baseURL,
  },
  BaseConfig.options,
);

export default {
  interceptors,
  options,
};
