import { formatPrice } from '@white-label-helper/helper-payment';
import { myApp } from '@white-label-plugin/global-app-context';

// Types
import type { SummaryExtras, SummaryTaxFee } from '@white-label-types/summary';
import type { ExtrasCancellationProtectionProduct, TaxFee } from '@white-label-types/parking-booking';

type CancellationProtection = ExtrasCancellationProtectionProduct['cancellation_protection'];

// eslint-disable-next-line
export const parseExtras = (cancellationProtection: CancellationProtection | any): SummaryExtras | {} => {
  if (cancellationProtection) {
    return {
      id: 'canc_prot',
      // @ts-expect-error - no reference to i18n
      name: myApp.i18n.t('shared.summaryParkings.cancellationProtection'),
      totals: {
        total: cancellationProtection.totals.total,
        total_formatted: formatPrice(cancellationProtection.totals.total),
        total_no_taxes: cancellationProtection.totals.subtotal,
        total_no_taxes_formatted: formatPrice(cancellationProtection.totals.subtotal),
      },
      items: cancellationProtection.taxes.map((item: TaxFee) => ({
        name: item.name,
        amount: item.amount,
        amount_formatted: formatPrice(item.amount),
      })),
    };
  }

  return {};
};

export const parseTaxesAndFees = (items?: TaxFee[]): SummaryTaxFee[] | [] => {
  if (Array.isArray(items) && items.length > 0) {
    return items.map(({ name, amount }) => ({
      name,
      amount: formatPrice(amount),
    }));
  }

  return [];
};
