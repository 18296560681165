import defaultsDeep from 'lodash/defaultsDeep';
import { Options } from './axios-config';
import BaseConfig from './http.base-instance-config';
import { getAppVariable } from '@white-label-helper/get-app-variable';

const { interceptors } = BaseConfig;
const baseURL = `${getAppVariable('regional_config').domains.booking}/${getAppVariable('api_version')}`;

interface BookingInstance extends Options {
  baseURL: string | undefined;
}

const options: BookingInstance = defaultsDeep(
  {
    baseURL,
    headers: {
      Authorization: `Bearer ${process.env['NUXT_ENV_AFFILIATE_APPLICATION_TOKEN']}`,
    },
  },
  BaseConfig.options,
);

export default {
  interceptors,
  options,
};
