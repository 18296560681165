
import { defineComponent, PropType } from 'vue';
import { TextField } from 'ui-shared-components';
import IconShow from '@white-label-icon/icon-show';
import IconHide from '@white-label-icon/icon-hide';

type PasswordHints = { match: boolean; text: string }[];

// These values are coming from the Payment page
interface VuelidateErrorObject {
  containsLowercase: boolean;
  containsUppercase: boolean;
  containsNumber: boolean;
  minLength: boolean;
}

export default defineComponent({
  name: 'PasswordField',

  components: {
    TextField,
    IconShow,
    IconHide,
  },

  props: {
    value: {
      type: String,
      required: true,
    },

    error: {
      type: Object as PropType<VuelidateErrorObject>,
      default: () => ({}),
    },
  },

  data() {
    return {
      focused: false,
      showPassword: false,
    };
  },

  computed: {
    passwordError(): boolean {
      return !!this.value && this.passwordStrength <= 1;
    },

    passwordWarning(): boolean {
      return (
        !!this.value && this.passwordStrength >= 2 && this.passwordStrength <= 3
      );
    },

    passwordSuccess(): boolean {
      return !!this.value && this.passwordStrength === 4;
    },

    displayPasswordStrength(): boolean {
      return this.focused && !!this.value;
    },

    displayPasswordHints(): boolean {
      return this.focused && !!this.value;
    },

    passwordHints(): PasswordHints {
      const error = this.error as unknown as VuelidateErrorObject;

      return [
        {
          match: error?.containsLowercase,
          text: 'Have at least one lower case character',
        },
        {
          match: error?.containsUppercase,
          text: 'Have at least one capital character',
        },
        { match: error?.containsNumber, text: 'Have at least one number' },
        { match: error?.minLength, text: 'Be at least 8 characters' },
      ];
    },

    passwordStrength(): number {
      return this.passwordHints.filter((val) => val.match).length;
    },

    passwordContainerRef(): HTMLElement {
      return this.$refs.passwordContainer as HTMLElement;
    },

    textFieldRef(): Vue {
      return this.$refs.textField as Vue;
    },
  },

  methods: {
    onInput(e: InputEvent): void {
      this.$emit('input', e);
    },

    onFocus(): void {
      this.focused = true;
    },

    onBlur(): void {
      this.focused = false;
    },

    onKeypress(e: KeyboardEvent): void {
      if (e.key === 'Enter') {
        // Focus state should disappear by clicking the enter button from keyboard
        this.textFieldRef.$el.querySelector('input')?.blur();
      }
    },

    togglePasswordVisibility(): void {
      const input = this.passwordContainerRef?.querySelector('input');
      if (input) {
        this.showPassword = !this.showPassword;
        input.type = this.showPassword ? 'text' : 'password';
      }
    },
  },
});
