import defaultsDeep from 'lodash/defaultsDeep';
import type { Options } from './axios-config';
import BaseConfig from './http.base-instance-config';
import { getAppVariable } from '@white-label-helper/get-app-variable';

const { interceptors } = BaseConfig;
const baseURL = `${getAppVariable('regional_config').domains.search}/v2`;

interface ProductInstance extends Options {
  baseURL: string | undefined;
}

const options: ProductInstance = defaultsDeep({
  baseURL,
},
BaseConfig.options,
);

export default {
  interceptors,
  options,
};
