
// Packages
import { defineComponent, PropType } from 'vue';

// Components
import BTicketWrap from '../b-ticket-wrap/b-ticket-wrap.vue';
import EntryExitInfo from '../entry-exit-info/entry-exit-info.vue';
import BTicketGuests from '../b-ticket-guests/b-ticket-guests.vue';
import BTicketPrivateLounge from '../b-ticket-private-lounge-details/b-ticket-private-lounge-details.vue';

// Store
import { readProductTypesCategories } from '@white-label-store/manage-booking';

// Types
import type { BookingDetails } from '@white-label-types/user-details';

export default defineComponent({
  name: 'UpdateDetailsTicket',

  components: {
    BTicketWrap,
    BTicketGuests,
    EntryExitInfo,
    BTicketPrivateLounge,
  },


  props: {
    bookingDetails: {
      type: Object as PropType<BookingDetails>,
      required: true,
    },
  },
  computed: {
    productOption(): string {
      return this.bookingDetails?.inventory_item?.product_option?.code;
    },
    isPrivateLounge(): boolean {
      return this.productOption === 'lounge_private';
    },
  },
  methods: {
    isProductTypeIncluded(key: string): boolean {
      return readProductTypesCategories(this.$store).includes(key);
    },
  },
});
