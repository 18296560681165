
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TextEditor',

  props: {
    value: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 300,
    },
    placeholder: {
      type: String,
    },
    label: {
      type: String,
    },
    error: {
      type: Object,
      default: () => ({}),
    },
    errorMessage: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getCharCount() {
      if (!this.value) return this.maxLength; // Handle case where this.value is undefined or empty
      return this.maxLength - this.value.length;
    },
  },
  data() {
    return {
      inputText: '',
      inputInvalid: false,
    };
  },

  methods: {
    validateInput(e) {
      let value = e.target.value;
      this.$emit('input', value);
      this.inputInvalid = this.charCount < 0;
    },
  },
});
