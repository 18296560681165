

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductSummaryGuestsInfo',
  props: {
    product: {
      type: Object,
      required: true
    },
    displayTravelExtrasSummary: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    guests() {
      return this.product?.inventory_option?.sub_options
        .map(product => ({
            name: product.details.name_formatted,
            key: product.details.name,
            quantity: product.totals.quantity
          })
        );
    }
  }
});

