
import { defineComponent } from 'vue';
// Mixins
import auth from '@white-label-helper/mixin-auth';
import {
  readManageBookingItems,
  readId,
} from '@white-label-store/manage-booking';
import { required, sameAs } from '@white-label-helper/vuelidate';

import DropdownWrapper from '../dropdown-wrapper/dropdown-wrapper.vue';
import RadioButton from '../radio-button/radio-button.vue';
import BackButton from '../back-button/back-button.vue';
import Checkbox from '../checkbox/checkbox.vue';
import IconAlert from '@white-label-icon/icon-alert';
import IconMessage from '../icon-message/icon-message.vue';
import {
  NAMED_ROUTES,
  LOUNGES_CATEGORY_TYPES,
} from '@white-label-configuration/constants';
import {
  getBookingBaggageDetails,
  setBookingBaggageDetails,
} from '@white-label-helper/api-manage-booking';

export default defineComponent({
  name: 'BookingBaggageDetails',
  components: {
    DropdownWrapper,
    RadioButton,
    BackButton,
    IconAlert,
    Checkbox,
    IconMessage,
  },
  mixins: [auth],
  data() {
    return {
      baggage: {
        baggage_count: '',
        baggage_declaration_agreement: false,
        has_oversized_baggage: null,
        custom_declaration: null,
      },
      bagsOpened: false,
      specialBaggageOptions: {
        false: this.$t('bookings.baggageDetails.specialBaggage.dontHave'),
        true: this.$t('bookings.baggageDetails.specialBaggage.have'),
      },
      customDeclarationOptions: {
        false: this.$t('bookings.baggageDetails.customDeclaration.dontHave'),
        true: this.$t('bookings.baggageDetails.customDeclaration.have'),
      },
    };
  },
  computed: {
    hasPassedDeadline() {
      return this.manageBookingItems.hasPassedDeadline;
    },
    infoMessage() {
      return {
        type: 'info',
        text: this.$t('guestDetails.questionnaireRequirement'),
        iconClass: 'text-brand-100',
      };
    },
    customError() {
      return this.$t('bookings.baggageDetails.validationErrors.declare');
    },
    bagsSelections() {
      const array = [];
      Array(20)
        .fill(0)
        .forEach((_, i) => {
          if (i > 0) {
            array.push({ id: i + '', name: i + '' });
          }
        });
      array.push({
        id: '20+',
        name: '20+',
      });
      return array;
    },
    getManagementToken() {
      const token = this.$cookies.get('manageBookingToken')?.token;

      return token;
    },
    getId() {
      const id = this.$cookies.get('manageBookingToken')?.id;
      return id || readId(this.$store);
    },

    manageBookingItems() {
      return (
        readManageBookingItems(this.$store).find(
          (booking) => booking.id === this.getId
        ) || {}
      );
    },
    detailsSource() {
      return this.manageBookingItems?.details?.baggage_type
        ? this.manageBookingItems.details
        : this.manageBookingItems.inventory_item_details;
    },
    isCheckedBaggage() {
      return (
        this.detailsSource?.baggage_type ===
        LOUNGES_CATEGORY_TYPES.CHECKED_BAGGAGE
      );
    },
  },
  async mounted() {
    const data = await getBookingBaggageDetails(
      this.getToken,
      this.getManagementToken,
      this.getId
    );
    if (!data) return;
    this.baggage = data;
    this.baggage.baggage_count = {
      id: this.baggage.baggage_count,
      name: this.baggage.baggage_count,
    };
  },
  validations(): object {
    return {
      baggage: {
        baggage_declaration_agreement: { required, sameAs: sameAs(() => true) },
        baggage_count: { required },
        has_oversized_baggage: { required },
        custom_declaration: { required },
      },
    };
  },
  methods: {
    goToBookingGuests() {
      this.$router.push(NAMED_ROUTES.booking_guest_details.home);
    },
    submit() {
      this.$v.baggage.$touch();
      if (
        !this.baggage.baggage_declaration_agreement ||
        this.$v.baggage.custom_declaration.$error ||
        (this.isCheckedBaggage && this.$v.baggage.has_oversized_baggage.$error)
      )
        return;
      if (!this.isCheckedBaggage) {
        delete this.baggage.has_oversized_baggage;
        delete this.baggage.baggage_count;
      }
      const baggageData = {
        ...this.baggage,
        baggage_type: this.detailsSource?.baggage_type,
        baggage_count: (this.baggage.baggage_count || {}).name,
      };
      setBookingBaggageDetails(
        this.getToken,
        this.getManagementToken,
        this.getId,
        baggageData
      ).then((res) => {
        if (res) this.goToBookingGuests();
      });
    },
  },
});
