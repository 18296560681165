
// Types
import type { PropType } from 'vue';
import type { Discount } from '@white-label-types/parking-checkout';

// Packages
import { defineComponent } from 'vue';
import { required } from '@white-label-helper/vuelidate';

import IconButton from '@white-label-icon/icon-button';
import IconPriceTag from '@white-label-icon/icon-price-tag';

// Components
import Button from '../button/button.vue';
import TextField from '../text-field/text-field.vue';

export default defineComponent({
  name: 'DiscountCode',

  components: {
    ControlButton: Button,
    IconButton,
    IconPriceTag,
    TextField,
  },

  props: {
    discountCode: {
      type: Object as PropType<Discount>,
      default: () => ({
        code: '',
        amount: 0,
        description: '',
        subtotal: 0,
        total: 0,
        amountFormatted: '',
        subtotalFormatted: '',
        totalFormatted: '',
      }),
    },

    loading: {
      type: Boolean,
      default: false,
    },

    discountError: {
      type: String,
      default: '',
    },
  },

  validations: {
    code: {
      required,
    },
  },

  data() {
    return {
      code: '',
      appliedCode: this.discountCode.code,

      validationMessages: {
        discountCode: this.$t('UI.formErrors.discountCode'),
      },

      isAdded: false,
      isAlreadyApplied: false,
    };
  },

  watch: {
    discountCode: {
      deep: true,
      handler(newDiscount: Discount) {
        if (newDiscount.code) {
          this.appliedCode = newDiscount.code;
          this.isAdded = true;
        } else {
          this.appliedCode = '';
          this.isAdded = false;
        }
      },
    },
  },

  mounted() {
    if (this.discountCode.code) {
      this.isAdded = true;
      this.code = this.discountCode.code;
    }
  },

  methods: {
    onRemoveDiscountCode() {
      this.isAdded = false;
      this.$emit('removeDiscountCode', this.discountCode.code);
      this.code = '';
      this.appliedCode = '';
      this.isAlreadyApplied = false;
      this.$v.$reset();
    },

    onAddDiscountCode() {
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.isAlreadyApplied =
        this.appliedCode === this.code && this.appliedCode !== '';
      if (this.isAlreadyApplied) return;

      this.$emit('addDiscountCode', this.code);
    },

    onFocus() {
      this.$v.$reset();
      this.isAlreadyApplied = false;
      this.$emit('input-focus');
    },
  },
});
