/**
 * @file timeHelpers.js
 * @description Helper functions for time conversions
 */
// Types
import type { DateObject } from '@white-label-types/date-times';

import {
  format,
  differenceInHours,
  utcToZonedTime,
  parseISO,
  DATE_TIME_FORMATS,
} from '@white-label-helper/date-utilities';

/**
 * Convert time object to string
 * @param dateObject - date time in object format
 * @returns dateString - date time in string format
 */
export function convertTimeObjToString({ date, time }: DateObject): string {
  if (!date || !time) {
    return '';
  }
  return `${date} ${time.value}`;
}

/**
 * Convert time string to object
 * @param dateString - date time in string format
 * @returns dateObject - date time in object format
 */
export function convertTimeStringToObj(dateString: string): DateObject {
  const date = parseISO(dateString);

  return {
    date: format(date, DATE_TIME_FORMATS.year_month_day),
    time: {
      name: format(date, DATE_TIME_FORMATS.time),
      value: format(date, DATE_TIME_FORMATS.time_24hr),
    },
  };
}

/**
 * Check if the entryDateTime is less than one hour from now
 * @param entryDateTime - date time in object format
 * @param timeZone - Time zone of partner location
 */
export function checkIsLessOneHour(
  entryDateTime: DateObject | string,
  timeZone: string
): boolean {
  let entryDate = entryDateTime;

  if (typeof entryDate !== 'string' && 'date' in entryDate) {
    entryDate = entryDate.date;
  }

  return (
    differenceInHours(
      parseISO(entryDate),
      utcToZonedTime(new Date(), timeZone)
    ) < 1
  );
}

/**
 * Helper function for search forms
 * Convert time string in format `24:00` to object
 * @param timeString
 */
export function convertTimeStringToObject(timeString: string) {
  const [hours, minutes] = timeString.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const convertedHours = hours % 12 || 12;
  function num2text(num: number | string) {
    return String(num).substring(0, 2).padStart(2, '0');
  }
  return {
    name: `${num2text(convertedHours)}:${num2text(minutes)}${period}`,
    value: `${num2text(hours)}:${num2text(minutes)}`,
  };
}
