/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
// Types
import type { Booking } from '@white-label-types/account-booking';
import type { ParsedPagination } from '@white-label-types/api';

// Helpers
import { getBookingsForUser } from '@white-label-helper/api-parkings-bookings';
import { parseOrdersResponse } from '@white-label-helper/data-parsers-bookings';
import { parseJSONApiPagination } from '@white-label-helper/json-api';


export const useBookingsStore = defineStore('bookings', () => {
  const bookings = ref<Booking[]>([]);
  const pagination = ref<ParsedPagination>({
    currentPage: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    perPage: 0,
    from: 0,
    to: 0,
    totalCount: 0,
    links: {
      first: null,
      last: null,
      next: null,
      prev: null,
    },
  });

  const bookingsNextPageCount = computed(() => {
    if (!pagination.value.hasNextPage) {
      return 0;
    } else if (
      pagination.value.totalCount - pagination.value.to <
      pagination.value.perPage
    ) {
      return pagination.value.totalCount - pagination.value.to;
    } else {
      return pagination.value.perPage;
    }
  });

  async function fetchUserBookings(
    payload: { bearerToken: string; queryParams?: Record<string, any> }
  ) {
    const currentPage = pagination.value.currentPage;
    return getBookingsForUser(payload.bearerToken, payload.queryParams)
      .then(({ data, links, meta }) => {
        const bookingsResponse = parseOrdersResponse(data);
        const apiPagination = parseJSONApiPagination({ meta, links });
        const appendBookings = apiPagination.currentPage > currentPage;

        if (!appendBookings) {
          bookings.value = [...bookingsResponse];
        } else {
          bookings.value = [...bookings.value, ...bookingsResponse];
        }

        pagination.value = { ...apiPagination };
      })
      .catch((error: any) => {
        throw new Error(error);
      });
  };

  async function fetchUserBookingsNextPage(
    payload: { bearerToken: string; queryParams?: Record<string, any> }
  ) {
    const { bearerToken, queryParams } = payload;
    return fetchUserBookings({
      bearerToken,
      queryParams: { ...queryParams, page: pagination.value.currentPage + 1 },
    });
  };

  return {
    bookings,
    pagination,
    bookingsNextPageCount,
    fetchUserBookings,
    fetchUserBookingsNextPage,
  }
});
