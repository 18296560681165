// this helper parses all necessary discount data from given object
// the resulting object can be safely passed to summaryProps

import { formatPrice } from '@white-label-helper/helper-payment';

import type { SummaryDiscount } from '@white-label-types/summary';
import type { ManageBookingState as State } from '@white-label-types/stores';
import type { Item as ReceiptItem } from '@white-label-types/parking-checkout';

type Data = State['manageBookingState'] | ReceiptItem;

export const createDiscountSummaryProp = (data: Data): SummaryDiscount | null => {
  if (data?.discount && data?.totals) {
    const { description, amount } = data.discount;
    const { total } = data.totals;

    const amountFormatted = formatPrice(amount);
    const subtotalFormatted = formatPrice(total || 0);

    if (amount && description && amountFormatted && subtotalFormatted) {
      return {
        amount,
        description,
        amountFormatted,
        subtotalFormatted,
      };
    }
  }

  return null;
};
