import { get, cloneDeep } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getAppColor(key: string, defaultValue?: any): any {

  if (process.env.NUXT_ENV_PARTNER_COLORS_JSON === undefined) {
    throw new ReferenceError('The "NUXT_ENV_PARTNER_COLORS_JSON" environment variable must be defined')
  }

  if (process.env.NUXT_ENV_PARTNER_COLORS_JSON === '') {
    throw new ReferenceError('The "NUXT_ENV_PARTNER_COLORS_JSON" environment variable must be a raw JSON string')
  }

  let parsedPartnerConfig

  try {
    parsedPartnerConfig = JSON.parse(process.env.NUXT_ENV_PARTNER_COLORS_JSON)
  }

  catch (error) {
    throw new Error('The "NUXT_ENV_PARTNER_COLORS_JSON" environment variable is set but not parsable as JSON')
  }

  const variable = cloneDeep(get(parsedPartnerConfig, key));

  if (variable || typeof variable === 'boolean') {
    return variable;
  }

  // WL-4568 Temporary solution
  console.warn(`Missing '${key}' in partner colors object`);
  return defaultValue;
}
