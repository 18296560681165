
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Tooltip',

  props: {
    content: {
      type: String,
      default: '',
    },
    // 'parent-control' for displaying the component
    elementAllowsTooltip: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showTooltip: false,
    };
  },

  watch: {
    elementAllowsTooltip(): void {
      if (!this.elementAllowsTooltip) {
        this.showTooltip = this.elementAllowsTooltip;
      }
    },
  },

  methods: {
    allowTooltip(): void {
      this.showTooltip = this.textIsTruncated() && this.elementAllowsTooltip;
    },

    disableTooltip(): void {
      this.showTooltip = false;
    },

    textIsTruncated(): boolean {
      const defaultSlot = this.$slots?.default;
      const elm =
        defaultSlot && defaultSlot?.length > 0
          ? (defaultSlot[0].elm as HTMLElement)
          : null;
      if (elm) {
        return Math.ceil(elm.getBoundingClientRect().width) <= elm.scrollWidth;
      }
      return false;
    },
  },
});
