/**
 * Print helper to support printing across all browsers.
 */
export const print = () => {
  let printing = false;
  window.onbeforeprint = () => {
    printing = true;
  };

  // Required for Safari
  document.execCommand('print', false);

  // Required for Firefox/Edge
  if (!printing) {
    setTimeout(() => {
      window.print();
    }, 100);
  };

  window.onbeforeprint = () => {};
}
