
import { defineComponent } from 'vue';
import EntryExitInfo from '../entry-exit-info/entry-exit-info.vue';

export default defineComponent({
  name: 'BTicketTimeInfo',

  components: {
    EntryExitInfo
  },

  props: {
    entryDateTime: {
      type: String,
      default: '',
    },

    exitDateTime: {
      type: String,
      default: '',
    },
  },
});
