
import { defineComponent } from 'vue';
import IconMinus from '@white-label-icon/icon-minus';
import IconPlus from '@white-label-icon/icon-plus';


export default defineComponent({
  name: 'NumSelector',
  components: {
    IconMinus,
    IconPlus,
  },
  props: {
    minusDisabled: {
      type: Boolean,
      default: false,
    },
    plusDisabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 0,
    },
    location: {
      type: String,
    }
  },
  computed: {
    items (): number[] {
      return Array(Math.max(+this.value + 3, 10)).fill(0).map((_, num) => num);
    },
  },
  methods: {
    onChange (value: string) {
      this.$emit('input', value);
    },
    add (num: number, isDisabled: boolean) {
      if (isDisabled) {
        this.$emit('error', { num, isDisabled });
      } else {
        this.$emit('input', +this.value + num);
      }
    },
  },
});
