import { defineStore } from 'pinia';

type HelperStoreState = {
  screenSize: number;
  isAppMounted: boolean;
}

/**
 *
 * @returns {StoreDefinition}
 */
export const useHelpersStore = defineStore({
  id: 'helpers',

  actions: {
    commitAppInitialized(payload: boolean) {
      this.isAppMounted = payload;
    },

    commitScreenSize(payload: number) {
      this.screenSize = payload;
    },
  },

  getters: {
    readIsAppMounted: (state) => state.isAppMounted,
    readScreenSize: (state) => state.screenSize,
  },

  state: () => {
    const defaultHelpersState: HelperStoreState = {
      screenSize: 0,
      isAppMounted: false,
    }

    return defaultHelpersState;
  }
})
