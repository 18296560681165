
import { defineComponent, PropType } from 'vue';
import type { Policy } from '@white-label-types/parking-booking';

import ToggleContent from '../toggle-content/toggle-content.vue';
import { getDomainUrl, DOMAINS_NAMES } from '@white-label-helper/switch-app';

export default defineComponent({
  name: 'BTicketPolicies',

  components: {
    ToggleContent,
  },

  props: {
    cancellationPolicies: {
      type: Array as PropType<Policy[]>,
      default: () => [],
    },

    amendmentPolicies: {
      type: Array as PropType<Policy[]>,
      default: () => [],
    },
    isParkingProduct: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      termsURL: process.env.NUXT_ENV_TERMS_URL,
    };
  },

  computed: {
    hasCancellationPolicies(): boolean {
      return !!this.cancellationPolicies.length;
    },

    hasAmendmentPolicies(): boolean {
      return !!this.amendmentPolicies.length;
    },

    hasCancellationOrAmendmentPolicies(): boolean {
      return this.hasAmendmentPolicies || this.hasCancellationPolicies;
    },
  },

  mounted() {
    if (!this.termsURL) {
      const ecommerceUrl = getDomainUrl(DOMAINS_NAMES.ECOMMERCE);
      this.termsURL = `${ecommerceUrl}/terms-and-conditions/`;
    }
  },
});
