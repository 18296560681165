// Packages
import { getStoreAccessors } from 'typesafe-vuex';

// Helpers
import { formatPrice } from '@white-label-helper/helper-payment';
import { validateDiscountCode, removeDiscountCode } from '@white-label-helper/api-parkings-discount-code';

// Types
import type {
  RootState,
  DiscountCodeState,
  DiscountCodeGetters,
  DiscountCodeMutations,
  DiscountCodeActions,
} from '@white-label-types/stores';

// Stores
import useDepositStore from '@white-label-store/deposits';

const discountCodeState = (): DiscountCodeState => ({
  code: '',
  amount: 0,
  description: '',
  subtotal: 0,
  total: 0,
  deepLinkCode: '',
});

const discountGetters: DiscountCodeGetters = {
  discountObject: (state) => ({
    ...state,
    amountFormatted: formatPrice(state.amount),
    subtotalFormatted: formatPrice(state.total),
    totalFormatted: formatPrice(state.total),
  }),

  discountCode: (state) => state.code,

  discountAmount: (state) => state.amount,

  subtotal: (state) => state.subtotal,

  total: (state) => state.total,

  currentDeepLinkCode: (state) => state.code || state.deepLinkCode,

  discountQuery: (_state, getters) => {
    let param = '';
    if (getters.currentDeepLinkCode) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      param = new URLSearchParams({ discount: getters.currentDeepLinkCode }).toString();
    }

    return param;
  },
};

const mutations: DiscountCodeMutations = {
  storeDiscountCode(state, payload) {
    state.code = payload.code;
    state.amount = payload.amount;
    state.description = payload.description;
    state.subtotal = payload.subtotal;
    state.total = payload.total;
  },

  storeDeepLinkCode(state, payload) {
    state.deepLinkCode = payload;
  },

  clearDiscountCode(state) {
    state.code = '';
    state.amount = 0;
    state.description = '';
    state.subtotal = 0;
    state.total = 0;
  },
};

const actions: DiscountCodeActions = {
  validateDiscountCode({ commit }, { cartToken, code }) {
    if (!code) {
      commit('clearDiscountCode');
      return new Promise<void>((resolve) => {
        resolve();
      });
    }

    return validateDiscountCode(cartToken, code)
      .then((response) => {
        if (response) {
          useDepositStore().updateCartDepositTotal(response.payable);
          commit('storeDiscountCode', response);
        } else {
          commit('clearDiscountCode');
        }
        return response;
      });
  },

  removeDiscountCode({ commit }, { cartToken, code }) {
    return removeDiscountCode(cartToken, code)
      .then((data) => {
        useDepositStore().updateCartDepositTotal(data.payable);
        commit('clearDiscountCode');
        return data;
      });
  },
};

const { read, commit, dispatch } = getStoreAccessors<DiscountCodeState, RootState>('discountCode');
// Getters
export const readDiscountQuery = read(discountGetters.discountQuery);
export const readDiscountObject = read(discountGetters.discountObject);

// Mutations
export const commitDiscountCode = commit(mutations.storeDiscountCode);
export const commitStoreDeepLinkDiscount = commit(mutations.storeDeepLinkCode);
export const commitClearDiscountCode = commit(mutations.clearDiscountCode);

// Actions
export const dispatchValidateDiscountCode = dispatch(actions.validateDiscountCode);
export const dispatchRemoveDiscount = dispatch(actions.removeDiscountCode);

export default {
  namespaced: true,
  state: discountCodeState,
  getters: discountGetters,
  mutations,
  actions,
};
