// Types
import { ImgErrorMixin } from './mixin-img-error.d';

const imgError: ImgErrorMixin = {
  data() {
    return {
      imgError: false,
    };
  },

  methods: {
    imgNotLoading(error = false) {
      // eslint-disable-next-line no-console
      if (error) console.error(`${error}: Map Not Loading`);
      this.imgError = true;
    },
  },
};

export default imgError;
