import { httpUser } from '@white-label-configuration/http';

// Types
import type { UserMarketingSubscription } from '@white-label-types/parking-shared';

export const getUserMarketingSubscription = async (email: string): Promise<UserMarketingSubscription | null> => {
  try {
    const response = await httpUser.get('/marketing-preferences', {
      query: {
        email
      }
    })

    return response.body.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const error = JSON.parse(e.message);

    if (error.body?.code?.toLowerCase().includes('no_user')) {
      console.warn(error.body.code, error.body.message);
      return null;
    } else {
      throw new Error(error.message);
    }
  }
}
