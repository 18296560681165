
export default {
  name: "Webloyalty",
  props: {
    hash: String,
    ckw: String
  },
  methods: {
    encode(v: string): string {
      let hex = "";
      for (let i = 0; i < v.length; i++) {
        hex += "" + v.charCodeAt(i).toString(16);
      }
      return hex;
    }
  },
  mounted() {
    // @ts-expect-error
    if (window.prodID?.a) {
      // @ts-expect-error
      window.prodID.a.push({ "h": this.encode(this.hash), "ckw":this.ckw, "r": false });
    }
  }
}
