
import { defineComponent, PropType } from 'vue';
import IconArrow from '@white-label-icon/icon-arrow';

const typesOfIconPosition = {
  left: 'left',
  right: 'right',
} as const;

const typesOfContentPosition = {
  top: 'top',
  bottom: 'bottom',
} as const;

export default defineComponent({
  name: 'ToggleContent',

  components: {
    IconArrow,
  },

  props: {
    buttonId: {
      type: [String, Number] as PropType<string | number>,
      default: '',
    },

    contentId: {
      type: [String, Number] as PropType<string | number>,
      default: '',
    },

    groupName: {
      type: String,
      default: '',
    },

    iconPosition: {
      type: String,
      default: typesOfIconPosition.left,
      validator (val: keyof typeof typesOfIconPosition) {
        return Object.keys(typesOfIconPosition).includes(val);
      },
    },

    contentPosition: {
      type: String,
      default: typesOfContentPosition.bottom,
      validator (val: keyof typeof typesOfContentPosition) {
        return Object.keys(typesOfContentPosition).includes(val);
      },
    },
  },

  data () {
    return {
      showOnPrint: true,
    };
  },

  methods: {
    toggleVisibility (e: MouseEvent) {
      const { target } = e;
      if (target instanceof HTMLButtonElement) {
        const EXPANDED_CLASS = 'toggle-content--expanded';
        const parent = target.parentNode as HTMLDivElement;
        const expanded = parent.classList.contains(EXPANDED_CLASS);

        document.querySelectorAll(`.${EXPANDED_CLASS}`).forEach((selector) => {
          if (this.groupName && parent.dataset.group === this.groupName) {
            selector.classList.remove(EXPANDED_CLASS);
          }
        });

        if (expanded) {
          parent.classList.remove(EXPANDED_CLASS);
          this.$emit('toggle', false);
          this.showOnPrint = true;
        } else {
          parent.classList.add(EXPANDED_CLASS);
          this.$emit('toggle', true);
          this.showOnPrint = false;
        }
      }
    },
  },
});
