
import { defineComponent } from 'vue';
import { TranslateResult } from 'vue-i18n';
import { format, DATE_TIME_FORMATS } from '@white-label-helper/date-utilities';

export default defineComponent({
  name: 'ProductSummaryFlightTimeInfo',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSearchProduct() {
      return !this.product.hasOwnProperty('inventory_item');
    },
    privateLoungeFlyingDirection() {
      let flyingDirection;

      if (this.isSearchProduct) {
        flyingDirection = this.product.productOptions[0]?.meta?.direction;
      } else {
        flyingDirection = this.product.details?.direction;
      }

      return flyingDirection;
    },
    flightTime(): TranslateResult {
      let flightDateTime = this.product.details.entry_date;

      if (this.privateLoungeFlyingDirection === 'outbound') {
        flightDateTime =
          this.product.product?.closed_at.datetime ??
          this.product.details.exit_date;
      } else if (this.privateLoungeFlyingDirection === 'inbound') {
        flightDateTime =
          this.product.product?.started_at.datetime ??
          this.product.details.entry_date;
      }

      const flightTime = format(
        new Date(flightDateTime),
        DATE_TIME_FORMATS.day_month_year_day_time_am_pm_lower_case
      );

      return this.$t('UI.productSummaryCard.info.flightTime', { flightTime });
    },
  },
});
