
import { defineComponent } from 'vue';
import { getAppVariable } from '@white-label-helper/get-app-variable';

export default defineComponent({
  name: 'InfoPrivateLounge',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baggageTypes: {
        checked_baggage: this.$t(
          'privateLoungeFeatures.baggage.checked_baggage'
        ),
        cabin_baggage: this.$t('privateLoungeFeatures.baggage.cabin_baggage'),
      },
      mealTypes: {
        carte_menu: this.$t('privateLoungeFeatures.meals.carte_menu'),
        no_food_included: this.$t(
          'privateLoungeFeatures.meals.no_food_included'
        ),
      },
      airlines: [],
    };
  },
  computed: {
    selectedAirline() {
      return (
        (this.airlines.length &&
          this.airlines.find(
            (i) =>
              i.iata === this.product.order_criteria?.lounges?.airline ||
              i.iata === this.product.search_criteria?.lounges?.airline
          )) || {
          name: '',
        }
      );
    },
  },
  mounted() {
    this.airlines = getAppVariable('products.lounges.airlines_list');
  },
});
