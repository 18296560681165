
import { defineComponent } from 'vue';

// Components
import { DotsLoader, BTicketWrap } from 'ui-shared-components';

export default defineComponent({
  name: 'UpdateDetailsPreloader',

  components: {
    DotsLoader,
    BTicketWrap,
  },
});
