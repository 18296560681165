import { isVue2, install, Vue2 } from 'vue-demi';
import { PiniaVuePlugin, createPinia, setActivePinia } from 'pinia';
if (isVue2) {
  install();
  var Vue = "default" in Vue2 ? Vue2.default : Vue2;
  Vue.use(PiniaVuePlugin);
}
var PiniaNuxtPlugin = function PiniaNuxtPlugin(context, inject) {
  var pinia = createPinia();
  if (isVue2) {
    context.app.pinia = pinia;
  } else {
    context.vueApp.use(pinia);
  }
  if (isVue2) {
    inject("pinia", pinia);
  } else {
    context.provide("pinia", pinia);
  }
  context.pinia = pinia;
  setActivePinia(pinia);
  pinia._p.push(function (_ref) {
    var store = _ref.store;
    Object.defineProperty(store, "$nuxt", {
      value: context
    });
  });
  if (process.server) {
    if (isVue2) {
      context.beforeNuxtRender(function (_ref2) {
        var nuxtState = _ref2.nuxtState;
        nuxtState.pinia = pinia.state.value;
      });
    } else {
      context.nuxtState.pinia = pinia.state.value;
    }
  } else if (context.nuxtState && context.nuxtState.pinia) {
    pinia.state.value = context.nuxtState.pinia;
  }
};
export { PiniaNuxtPlugin as default };