
import { defineComponent } from 'vue';
import BookingStatus from '../booking-status/booking-status.vue';
import ControlButton from '../button/button.vue';
import { BOOKING_STATUSES } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'BookingDetailsCard',
  components: { ControlButton, BookingStatus },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'incomplete',
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonMeta() {
      const basedOnStatusText =
        this.status === BOOKING_STATUSES.complete
          ? this.$t('guestDetails.edit')
          : this.$t('guestDetails.addDetails');
      return {
        type:
          this.viewMode || this.status === BOOKING_STATUSES.complete
            ? 'secondary'
            : 'primary',
        label: this.viewMode
          ? this.$t('guestDetails.viewDetails')
          : basedOnStatusText,
      };
    },
  },
});
