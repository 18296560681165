
import { defineComponent } from 'vue';
import ProductTags from '../product-tags/product-tags.vue';

export default defineComponent({
  name: 'BTicketHeader',
  components: { ProductTags },

  props: {
    product: {
      type: Object,
      required: true,
    },

    productName: {
      type: String,
      default: '',
    },

    airportName: {
      type: String,
      default: '',
    },

    orderId: {
      type: String,
      default: '',
    },
  },
});
