
import { defineComponent, PropType } from 'vue';
import type { PurchaseProduct } from "@white-label-types/parking-booking";
import type { Total } from '@white-label-types/parking-checkout';

import TicketPreloader from '../ticket-preloader/ticket-preloader.vue';
import ReceiptBookingOrder from '../receipt-booking-order/receipt-booking-order.vue';
import ReceiptBookingOrders from '../receipt-booking-orders/receipt-booking-orders.vue';
import AdditionalInfoPreloader from '../additional-info-preloader/additional-info-preloader.vue';
import PartnerizeTracking from '../partnerize-tracking/partnerize-tracking.vue';

export default defineComponent({
  components: {
    ReceiptBookingOrder: ReceiptBookingOrder,
    ReceiptBookingOrders: ReceiptBookingOrders,
    TicketPreloader,
    AdditionalInfoPreloader,
    PartnerizeTracking
  },

  props: {
    items: {
      type: Array as PropType<PurchaseProduct[]>,
      required: true,
    },

    orderTotals: {
      type: Object as PropType<Total>,
      default: () => ({}),
    },

    contentLoading: {
      type: Boolean,
    },

    orderReference: {
      type: String,
      default: '',
    },
    isParkingProduct: {
      type: Boolean,
      default: false,
    },

    modifications: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    /**
     * Returns value of featuree flag: ECOM-1770-Travel-Extras-Payment-Summary
     * @returns a boolean.
     */
     displayTravelExtrasSummary(): boolean {
      return this['$launchDarkly'].variation('ECOM-1770-Travel-Extras-Payment-Summary');
    },
  },
});
