interface PhoneDetails {
  phone_number: string | null
  formatted_number: string | null
  country_code: string | null
}

interface CustomerDetails {
  license_plate: string | null
  user_email: string
  user_last_name: string
  user_first_name: string
}

interface TypeGetUserData extends CustomerDetails {
  user_phone: PhoneDetails,
}

export interface ReturnTypeGetUserData extends CustomerDetails{
  phone: PhoneDetails,
}

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

/**
 * Parser the data we get from end point /pii-data/{token}.
 * It also renames 'user_phone' to 'phone'
 */
export const parserGetUserData = (data: TypeGetUserData): ReturnTypeGetUserData => {
  if (typeof data === 'undefined') {
    throw new Error('The user data can\'t be undefined')
  }

  const phoneNumberDetails = { phone: { ...data.user_phone } };
  const localCopy: Optional<TypeGetUserData, 'user_phone'> = data;

  if ('user_phone' in localCopy) {
    delete localCopy.user_phone;
  }

  return Object.assign(localCopy, phoneNumberDetails);
}
