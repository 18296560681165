
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    IconCheck: () => import('@white-label-icon/icon-check'),
  },
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },

    value: {
      type: Array,
      required: true,
    },

    color: {
      type: String,
      default: 'brand',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },

  methods: {
    isChecked(option: string) {
      return this.value.includes(option);
    },
    onChange(option: string, event) {
      let newValue;
      if (event.target.checked) {
        newValue = [...this.value, option];
      } else {
        newValue = this.value.filter((item) => item !== option);
      }
      this.$emit('input', newValue);
    },
  },
});
