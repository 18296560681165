type This = {
  isBookingPortal: (this: This) => boolean
}

type BookingPortalMixin = {
  computed: {
      isBookingPortal: This['isBookingPortal']
  },
}

const bookingPortal: BookingPortalMixin = {
  computed: {
    isBookingPortal() {
      return process.env.NUXT_ENV_IS_BOOKING_PORTAL === 'true';
    },
  },
};

export  default bookingPortal;
