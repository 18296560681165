/* eslint-disable @typescript-eslint/ban-types */
type FormDataGenerator = {
  mainPropKey?: unknown;
  parentPropName?: {} | unknown[] | null;
  propIndex?: number | null;
  formData: FormData;
};

function appendToFormData(key: string, value: string | boolean, formData: FormData) {
  const localValue = (typeof value === 'boolean') ? Number(value) : value;
  formData.append(key, localValue.toString());
}

/**
 * FromData from simple and multidimensional object
 * @param data - object to FromData
 * @param mainPropKey - object prop name returned example: key: value
 * @param parentPropName - nested object/array prop name
 * @param propIndex - array element index
 * @param formData - FormData
 * */
function generateFormData(data: { [key: string]: string | boolean }, {
  mainPropKey = null,
  parentPropName = null,
  propIndex = null,
  formData,
}: FormDataGenerator): FormData {
  const removeUndefinedValue = Object.entries(data).filter(([, value]) => value !== undefined);

  removeUndefinedValue.forEach(([key, value]) => {
    // Check if we are passing in an array
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return appendToFormData(`${parentPropName}[${key}]`, '', formData);
      }

      value.forEach((item, index) => {
        return generateFormData(item, {
          parentPropName: key,
          mainPropKey: parentPropName,
          propIndex: index,
          formData,
        });
      });
    }

    // Check if we are passing in an object
    if (typeof value === 'object' && value !== null) {
      return generateFormData(value, {
        parentPropName: key,
        ...(parentPropName && {
          mainPropKey: parentPropName,
        }),
        formData,
      });
    }

    if (mainPropKey && parentPropName && typeof propIndex === 'number') {
      return appendToFormData(`${mainPropKey}[${parentPropName}][${propIndex}]`, value, formData);
    }

    if (mainPropKey && parentPropName) {
      return appendToFormData(`${mainPropKey}[${parentPropName}][${key}]`, value, formData);
    }

    if (parentPropName) {
      if (typeof propIndex === 'number') {
        return appendToFormData(`${parentPropName}[${propIndex}][${key}]`, value, formData);
      }
      return appendToFormData(`${parentPropName}[${key}]`, value, formData);
    }

    appendToFormData(key, value, formData);
  });

  return formData;
}

export default (data = {}) => generateFormData(data, { formData: new FormData() });
