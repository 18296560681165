
import { defineComponent } from 'vue';
import EntryExitInfo from '../entry-exit-info/entry-exit-info.vue';
import BTicketGuests from '../b-ticket-guests/b-ticket-guests.vue';
import BTicketExtras from '../b-ticket-extras/b-ticket-extras.vue';
import InfoPrivateLounge from '../b-ticket-private-lounge-details/b-ticket-private-lounge-details.vue';
import { PRODUCT_TYPES } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'BTicketBookingDetails',
  components: {
    BTicketGuests,
    BTicketExtras,
    EntryExitInfo,
    PrivateLoungeInfo: InfoPrivateLounge,
  },
  props: {
    subItems: {
      type: Array,
      default() {
        return [];
      },
    },

    entryTime: {
      type: String,
      default: '',
    },

    product: {
      type: Object,
      required: true,
    },

    exitTime: {
      type: String,
      default: '',
    },

    entryDateTime: {
      type: String,
      default: '',
    },

    exitDateTime: {
      type: String,
      default: '',
    },
  },
  computed: {
    visitingDurationMessage(){
      if(this.product?.inventory_item?.visiting_duration_message) {
        return this.product.inventory_item.visiting_duration_message;
      }
      if(this.product?.inventoryItem?.visiting_duration_message) {
        return this.product['inventoryItem'].visiting_duration_message;
      }
      return null;
    },
    productOption() {
      return (
        this.product?.inventory_item?.product_option?.code ||
        this.product?.inventoryItem?.product_option?.code
      );
    },
    isPrivateLounge() {
      return this.productOption === 'lounge_private';
    },
  },
  methods: {
    isExtrasProduct(){
      return this.product.product_code === PRODUCT_TYPES.EXTRAS;
    },
  }
});
