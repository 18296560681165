
// Packages
import { defineComponent, PropType } from 'vue';

// Types
import type { CartItem, CancellationProtection } from '@white-label-types/parking-checkout';
import type { CancellationPolicy, AmendmentPolicy, StandardPolicy } from '@white-label-types/parking-booking';

// Helpers
import { formatPrice } from '@white-label-helper/helper-payment';

import RadioButton from '../radio-button/radio-button.vue';

export default defineComponent({
  name: 'CancellationAndAmmendmentPolicy',

  components: {
    RadioButton,
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconShield: () => import('@white-label-icon/icon-shield'),
  },

  props: {
    cancellationPolicies: {
      type: Array as PropType<CancellationPolicy[] | CartItem['inventory_option']['cancellation_policies'][]>,
      default: () => [],
    },
    amendmentPolicies: {
      type: Array as PropType<AmendmentPolicy[] | CartItem['inventory_option']['amendment_policies'][]>,
      default: () => [],
    },
    isProtected: {
      type: Boolean,
      required: true,
    },
    cancellationProtection: {
      type: Array as PropType<CancellationProtection[]>,
      required: true,
    },
    isBookingFeeCharged: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      standardPolicyExpanded: false,
      upgradedProtectionExpanded: true,
      selectedOption: 'standardOption',
    };
  },

  computed: {
    upgradedProtectionLabel() {
      return `
        ${this.$t('cancellationAndAmendment.upgradedPolicy')}
        ${formatPrice(this.cancellationProtectionTotal)}
      `;
    },
    isCancellationProtectionAvailable(): boolean {
      return this.cancellationProtection.some(item => item.is_available);
    },
    cancellationProtectionTotal(): number {
      return this.cancellationProtection.reduce((acc, item) => acc + (item.totals?.total ?? 0), 0);
    },
    multiCartStandardPolicies(): StandardPolicy[] {
      // TODO: Remove when `ECOM_1771_MOVE_CANCELLATION_PROTECTION` is live
      if (!this.isCrossSellExtrasPageActive) {
        return [];
      }
      return this.cancellationPolicies.map((cancellationPolicyItem: CancellationPolicy) => {
        const amendmentPolicyItem = this.amendmentPolicies.find((item: AmendmentPolicy) => item.product_id === cancellationPolicyItem.product_id);
        return {
          ...cancellationPolicyItem,
          amendment_policy: amendmentPolicyItem ? amendmentPolicyItem.amendment_policy : '',
        } as StandardPolicy;
      });
    },
    isCrossSellExtrasPageActive(): boolean {
      // TODO: Remove when `ECOM_1771_MOVE_CANCELLATION_PROTECTION` is live
      return this.$launchDarkly.variation(
        'ECOM_1771_MOVE_CANCELLATION_PROTECTION'
      );
    },
    cancellationPolicyTitle(): string {
      return this.$t('cancellationAndAmendment.cancellationPolicy').toLowerCase();
    },
    amendmentPolicyTitle(): string {
      return this.$t('cancellationAndAmendment.amendmentPolicy').toLowerCase();
    },
  },

  mounted() {
    if (this.isProtected) {
      this.selectedOption = 'upgradedOption';
    }
  },

  methods: {
    onRadioSelect(value: string): void {
      this.selectedOption = value;

      if (value === 'standardOption') {
        this.$emit('remove-cancellation-protection');
      } else {
        this.$emit('add-cancellation-protection');
      }
    },
  },
});
