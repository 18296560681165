
// Packages
import { defineComponent } from 'vue';

// Constants
import {
  PROGRESS_INDICATOR_PAGES,
  MY_BOOKINGS_REDIRECTION_PAGES,
  PRODUCT_TYPES,
} from '@white-label-configuration/constants';

// Helpers
import {
  getDomainUrl,
  DOMAINS_NAMES,
  ACCOUNT_URLS,
} from '@white-label-helper/switch-app';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';

// Stores
import { mapState } from 'pinia';
import { useDeepLink } from '@white-label-store/deep-link';
import { useSearchResultsStore } from '@white-label-store/search-results';

// Types
import type { TabData } from '@white-label-types/navbar';

// Components
import Navbar from '../navbar/navbar.vue';
import ProgressIndicator from '../progress-indicator/progress-indicator.vue';

type RedirectionPagesKeys = keyof typeof MY_BOOKINGS_REDIRECTION_PAGES;
type RedirectionPagesValues =
  (typeof MY_BOOKINGS_REDIRECTION_PAGES)[RedirectionPagesKeys];

export default defineComponent({
  name: 'EcommerceNavbar',

  components: {
    NavbarParkings: Navbar,
    ProgressIndicator,
  },

  data() {
    return {
      PROGRESS_INDICATOR_PAGES,
      isParkingProduct: getAppHeroProduct('lounges') === 'parking',
    };
  },

  computed: {
    ...mapState(useDeepLink, ['discount']),
    ...mapState(useSearchResultsStore, [
      'inventoryMetadata',
    ]),
    displayProgress(): boolean {
      return Object.values(this.pages).includes(
        this.$route.name as RedirectionPagesValues
      );
    },

    crossSellMixedResultsFlagEnabled(): boolean {
      return this.$launchDarkly.variation('EM-369-CROSS-SELL-MIXED-RESULTS');
    },

    travelExtrasAvailable(): boolean {
      return this.crossSellMixedResultsFlagEnabled && (this.inventoryMetadata['has_lounges'] || this.inventoryMetadata['has_fasttrack']);
    },

    pages(): typeof PROGRESS_INDICATOR_PAGES {
      return PROGRESS_INDICATOR_PAGES;
    },

    productTypes() {
      return getAppVariable('product_types_available') ?? [];
    },

    extrasPage(): string {
      const crossSellExtrasAvailable = this.inventoryMetadata['has_lounges'];
      const extrasProductsAvailable = this.productTypes.includes(PRODUCT_TYPES.EXTRAS);
      const travelExtrasAvailable = this.travelExtrasAvailable;

      if (travelExtrasAvailable) {
        return PROGRESS_INDICATOR_PAGES.travel_extras;
      } else if (crossSellExtrasAvailable) {
        return PROGRESS_INDICATOR_PAGES.extras_lounges;
      } else if (extrasProductsAvailable) {
        return PROGRESS_INDICATOR_PAGES.extras_product;
      }

      return PROGRESS_INDICATOR_PAGES.extras;
    },

    tabData(): TabData {
      const domainUrl = getDomainUrl(DOMAINS_NAMES.ACCOUNT);
      const bookingLink = domainUrl ? domainUrl.concat(ACCOUNT_URLS.HOME) : '#';

      if (this.isParkingProduct && isBookingPortal) {
        return {};
      }
      return {
        tabsLinks: {
          bookingLink: bookingLink,
        },
      };
    },

    partner_name() {
      return getAppVariable('partner_name');
    },

    discountQuery() {
      return (
        this.discount && { discount: this.discount }
      );
    },

    logoURL() {
      return getAppVariable('logo');
    },

    logoRedirectURL() {
      return (
        getAppVariable('logo_redirect_url')
        || getDomainUrl(DOMAINS_NAMES.ECOMMERCE)
      );
    },

    shouldSkipExtrasStep(): boolean {
      const crossSellExtrasAvailable = this.inventoryMetadata['has_lounges'] !== false;
      const extrasProductsAvailable = this.productTypes.includes(PRODUCT_TYPES.EXTRAS);
      const travelExtrasAvailable = this.travelExtrasAvailable;
      return !(crossSellExtrasAvailable || extrasProductsAvailable || travelExtrasAvailable);
    },
  },
});
