
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BTicketGuests',

  components: {
    IconUser: () => import('@white-label-icon/icon-user'),
  },

  computed: {
    filteredSubItemsList() {
      const deprecatedItems = ['children_ages']
      return this.subItems.filter((item) => {
      const titleLower = item?.title?.toLowerCase() || '';
      return !deprecatedItems.some(deprecated => titleLower.includes(deprecated));
    });
    }
  },

  props: {
    subItems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
});
