import Vue from 'vue';
import { getAppVariable } from '@white-label-helper/get-app-variable';

export default Vue.mixin({
  computed: {
    isMultiBasket(): boolean {
      // @ts-expect-error - Problem with variation - any
      return this['$launchDarkly'].variation('PT-1514-MULTI-BASKET') === true && !!getAppVariable('is_multi_basket');
    },
  },
});
