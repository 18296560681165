
import { defineComponent } from 'vue';
import Button from '../button/button.vue';

import {
  DOMAINS_NAMES,
  getDomainUrl,
  goToApp,
  ECOMMERCE_URLS,
} from '@white-label-helper/switch-app';

export default defineComponent({
  name: 'ManageBookingButtonBlock',

  components: {
    ControlButton: Button,
  },

  methods: {
    goToBooking() {
      return goToApp(getDomainUrl(DOMAINS_NAMES.ECOMMERCE), ECOMMERCE_URLS.HOME);
    },
  },
});
