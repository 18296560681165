import type { AxiosInstance, AxiosResponse, AxiosStatic, Method } from 'axios';
import AxiosPlugin, { AxiosError, AxiosRequestConfig } from 'axios';
import type { Interceptors, Options } from '../config/axios-config';
import formDataGenerator from './formDataGenerator';

interface AxiosConfig {
  options: Options,
  interceptors: Interceptors,
}

interface CustomAxiosInstance extends AxiosInstance {
  CancelToken?: AxiosStatic['CancelToken'],
  isCancel?: AxiosStatic['isCancel'],
  onRequest?: Interceptors['onRequest'],
  onResponse?: Interceptors['onResponse'],
  onRequestError?: Interceptors['onRequestError'],
  onResponseError?: Interceptors['onResponseError'],
}

export interface CustomAxiosError {
  message: string;
  configUrl: string;
  config: AxiosRequestConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any,
  headers: AxiosError,
  status: number,
}

type MergeAxiosConfig = AxiosConfig & CustomAxiosInstance;

type HttpRequest = ReturnType<typeof httpRequest>;

type Settings = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: Record<string, any>,
  headers?: Record<string, string>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query?: Record<string, any>
};

type ExportedServiceInstance = {
  get(url: string, settings?: Settings): HttpRequest,
  post(url: string, settings: Settings): HttpRequest,
  put(url: string, settings: Settings): HttpRequest,
  patch(url: string, settings?: Settings): HttpRequest,
  delete(url: string, settings?: Settings): HttpRequest,
  head(url: string, settings: Settings): HttpRequest,
  options(url: string, settings: Settings): HttpRequest,
};

const AXIOS_HTTP_HELPERS = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'HEAD', 'OPTIONS'] as const;

/**
 * Request resolve parser
 */
const toResponse = (context: AxiosResponse) => ({
  body: context.data,
  status: context.status,
  headers: context.headers,
});

/**
 * Request error parser
 */
const toError = (err: AxiosError): never => {
  const {
    response,
    request,
    message,
    config,
  } = err;
  const configUrl = config?.url || '';

  throw new Error(JSON.stringify({
    message,
    configUrl,
    config,
    body: response?.data,
    headers: response?.headers,
    status: request?.status,
  }));
};

/**
 * Http request helper function
 */
const httpRequest = (
  url: string,
  method: Method,
  axiosInstance: MergeAxiosConfig,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { headers = {}, body = {}, query = {} }: any = {},
) => {
  const formDataIsSent = (headers['Content-Type'] === 'application/x-www-form-urlencoded');
  const requestOptions = {
    url,
    method,
    headers,
    data: body,
    params: query,
    /*
    * If request content type is FormData use formDataGenerator
    * to generate FromData from request body.
    *  */
    transformRequest: formDataIsSent ? formDataGenerator : undefined,
  };


  return axiosInstance(requestOptions)
    .then(toResponse)
    .catch(toError);
};

/**
 * Extend extra functions to Axios instance
 */
const extendAxiosInstance = (axiosInstance: CustomAxiosInstance, extras: Interceptors) => {
  /* eslint-disable no-param-reassign */
  axiosInstance.onRequest = extras.onRequest;
  axiosInstance.onResponse = extras.onResponse;
  axiosInstance.onRequestError = extras.onRequestError;
  axiosInstance.onResponseError = extras.onResponseError;
  /* eslint-enable no-param-reassign */
};

/**
 * Helper function returns object with axios methods
 * @param instance - Axios instance
 * @param httpHelpers - names of http methods
 * @param interceptors - array with axios interceptor names
 * @example {
 *   get: function(url = '', settings = {}) - return Promise,
 *   ...httpHelpers,
 *   onRequest: function(callback),
 *   ...interceptors
 * }
 */
function generateExportedServiceInstance(
  instance: MergeAxiosConfig,
  { interceptors = [] }: { interceptors: string[] },
): ExportedServiceInstance {
  const httpHelpers = AXIOS_HTTP_HELPERS;
  const helpersObject: ExportedServiceInstance = {} as ExportedServiceInstance;

  httpHelpers.forEach((helper) => {
    if (httpHelpers.includes(helper)) {
      // @ts-expect-error - review this
      helpersObject[helper.toLowerCase()] = (url = '', settings = {
        headers: {},
        data: {},
        params: {},
      }) => httpRequest(url, helper, instance, settings);
    }
  });

  interceptors.forEach((helper) => {
    if (interceptors.includes(helper)) {
      // @ts-expect-error - review this
      helpersObject[helper] = fn => instance[helper](fn);
    }
  });

  return helpersObject;
}

/**
 * Setup progress indicator manually, based on onRequest and onResponse interceptors
 */
const setupProgressIndicator = (axios: MergeAxiosConfig) => {
  const isProcessServer = process.server;


  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - '$nuxt' does not exist on type 'Window'
  if (isProcessServer || !window.$nuxt) { return; }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - '$nuxt' does not exist on type 'Window'
  const $loading = () => window.$nuxt.$loading;

  let currentRequests = 0;

  if (axios.onRequest) {
    axios.onRequest(() => {
      currentRequests += 1;

      $loading().start();
    });
  }

  if (axios.onResponse) {
    axios.onResponse(() => {
      currentRequests -= 1;

      if (currentRequests <= 0) {
        currentRequests = 0;
        $loading().finish();
      }
    });
  }
};

/**
 * Function returns created axios instance.
 * @param options - Axios config options;
 * @param interceptors - custom interceptors that should be extended to axios instance
 * */
export const createAxiosInstance = ({ options, interceptors }: AxiosConfig): ExportedServiceInstance => {
  const instance: CustomAxiosInstance = AxiosPlugin.create(options);
  instance.CancelToken = AxiosPlugin.CancelToken;
  instance.isCancel = AxiosPlugin.isCancel;

  instance.interceptors.request.use((config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...options.headers,
      ...config.headers,
    };

    return config;
  });

  // Merges the CustomAxiosInstance with the Interceptors
  extendAxiosInstance(instance, interceptors);
  setupProgressIndicator(instance as MergeAxiosConfig);

  return generateExportedServiceInstance(instance as MergeAxiosConfig, {
    interceptors: Object.keys(interceptors),
  });
};
