
import { defineComponent, PropType } from 'vue';
import type { LocaleMessage } from 'vue-i18n';
import type { Extras } from '@white-label-types/parking-booking';

import Button from '../button/button.vue';
import ToggleContent from '../toggle-content/toggle-content.vue';

export default defineComponent({
  name: 'SelectExtrasInfo',

  components: {
    ToggleContent,
    IconAdd: () => import('@white-label-icon/icon-add'),
    IconCheck: () => import('@white-label-icon/icon-check'),
    IconClose: () => import('@white-label-icon/icon-close'),
    Button,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    extras: {
      type: Array as PropType<Extras[]>,
      default: () => [
        {
          id: 1,
          title: 'Cancellation protection',
          description: 'Cancel your booking online up to 24 hours before the day of travel and avoid the $7.50 cancellation charge.',
          price: 1,
          details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        },
      ],
    },

    selectedExtras: {
      type: Array as PropType<unknown[]>,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    localExtras (): Extras[] {
      return this.extras.map(extra => ({
        ...extra,
        added: this.selectedExtras.includes(extra.id),
      }));
    },
  },

  methods: {
    toggleExtra (extra: Extras) {
      this.$emit('toggleExtra', extra);
    },

    getExtraButtonTitle (extra: Extras): LocaleMessage {
      return extra.added
        ? this.$t('pages.selectExtras.remove')
        : this.$t('pages.selectExtras.add');
    },

    getExtraButtonIcon (extra: Extras): string {
      return extra.added ? 'IconClose' : 'IconAdd';
    },

    extraTitleId (title: string): string {
      return title.replace(' ', '');
    },
  },
});
