import { COLOUR_SCHEMES } from '@white-label-configuration/constants';

export const LOGO_TYPES = {
  MASTERCARD: 'mastercard',
  VISA: 'visa',
  AMERICAN_EXPRESS: 'american_express',
  DISCOVER: 'discover'
} as const;

export const LOGO_THEME_MAP = {
  [COLOUR_SCHEMES.LIGHT]: {
    [LOGO_TYPES.MASTERCARD]: 'mastercard_white',
    [LOGO_TYPES.VISA]: 'visa',
    [LOGO_TYPES.AMERICAN_EXPRESS]: 'american_express',
    [LOGO_TYPES.DISCOVER]: 'discover_white',
  },
  [COLOUR_SCHEMES.DARK]: {
    [LOGO_TYPES.MASTERCARD]: 'mastercard',
    [LOGO_TYPES.VISA]: 'visa',
    [LOGO_TYPES.AMERICAN_EXPRESS]: 'american_express',
    [LOGO_TYPES.DISCOVER]: 'discover',
  },
} as const;