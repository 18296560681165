
// List of supported html button types.
// List of supported states of button.
// Controls color of background and text color.
// Colors is unique for each theme, and if brand exist also for it.
import { defineComponent, PropType } from 'vue';
import type { Route } from 'vue-router';
import {
  typeOfState,
  typesOfElement,
} from '@white-label-configuration/constants';

import IconEnvelope from '@white-label-icon/icon-envelope';
import IconPrinter from '@white-label-icon/icon-printer';
import IconShare from '@white-label-icon/icon-share';
import IconThreeArrows from '@white-label-icon/icon-three-arrows';
import IconUser from '@white-label-icon/icon-user';
import IconPlusCircle from '@white-label-icon/icon-plus-circle';

import DotsLoader from '../dots-loader/dots-loader.vue';

export type Props = {
  id: string;
  type: string;
  typeOfElement: keyof typeof typesOfElement;
  buttonType: string;
  to: Route;
  loading: boolean;
  disabled: boolean;
  appendIcon: string;
  prependIcon: string;
  isContentCentered: boolean;
};
export type Data = {
  typesOfElement: typeof typesOfElement;
};
export type Computed = {
  appendIconComponent: () => Vue.Component | string;
  prependIconComponent: () => Vue.Component | string;
  getTypeOfStateClass: () => string;
  getEnvClass: () => string;
  getDisabledState: () => string;
  getJustifyClass: () => string;
};
export type Methods = {
  btnClick: () => void;
};

export default defineComponent<Props, {}, Data, Computed, Methods>({
  name: 'Button',

  components: {
    DotsLoader
  },

  props: {
    id: {
      // id passed to selected type of element
      type: String,
      default: '',
    },

    testId: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },

    type: {
      // if typeOfElement === 'button' set to it type
      type: String,
      default: '',
    },

    typeOfElement: {
      // controls which html element will be used
      type: String as PropType<keyof typeof typesOfElement>,
      required: true,
      validator(val: keyof typeof typesOfElement) {
        return Object.keys(typesOfElement).includes(val);
      },
    },

    // type-of-element="button"
    buttonType: {
      // controls which color will have button
      type: String as PropType<keyof typeof typeOfState>,
      required: true,
      validator(val: keyof typeof typeOfState) {
        return Object.keys(typeOfState).includes(val);
      },
    },

    to: {
      // if typeOfElement === 'link' set route direction to it
      type: [String, Object] as PropType<string | Route>,
      default: () => {},
    },

    loading: {
      type: Boolean,
    },

    disabled: {
      type: Boolean,
    },

    // to display icon at the start use this props and pass the necessary icon component
    appendIcon: {
      type: String,
      default: '',
    },

    // to display icon at the end use this props and pass the necessary icon component
    prependIcon: {
      type: String,
      default: '',
    },

    isContentCentered: {
      type: Boolean,
    },
  },
  data() {
    return {
      typesOfElement,
    };
  },

  computed: {
    appendIconComponent(): Vue.Component | string {
      switch (this.appendIcon) {
        case 'IconEnvelope':
          return IconEnvelope;
        case 'IconPrinter':
          return IconPrinter;
        case 'IconPlusCircle':
          return IconPlusCircle;
        case 'IconShare':
          return IconShare;
        case 'IconThreeArrows':
          return IconThreeArrows;
        case 'IconUser':
          return IconUser;
      }
      return this.appendIcon;
    },
    prependIconComponent(): Vue.Component | string {
      switch (this.prependIcon) {
        case 'IconEnvelope':
          return IconEnvelope;
        case 'IconPrinter':
          return IconPrinter;
        case 'IconPlusCircle':
          return IconPlusCircle;
        case 'IconShare':
          return IconShare;
        case 'IconThreeArrows':
          return IconThreeArrows;
        case 'IconUser':
          return IconUser;
      }
      return this.prependIcon;
    },

    getTypeOfStateClass(): string {
      if (!this.buttonType) {
        return '';
      }
      return `button-${this.buttonType}`;
    },

    getEnvClass(): string {
      const { NUXT_ENV_THEME } = process.env;
      if (!NUXT_ENV_THEME) {
        return '';
      }
      return `button-${NUXT_ENV_THEME}`;
    },

    getDisabledState(): string {
      if (!this.disabled) {
        return '';
      }
      return `button-${this.buttonType}--disabled`;
    },

    getJustifyClass(): string {
      if (
        !this.isContentCentered &&
        (this.appendIcon || this.prependIcon) &&
        !this.loading
      ) {
        return 'justify-between';
      }
      return 'justify-center';
    },
  },

  mounted() {
    // custom validation
    // if typeOfElement === 'link' prop 'to' must be declared
    if (this.typeOfElement === this.typesOfElement.link && !this.to) {
      throw new Error(
        'If typeOfElement === "link", prop "to" must contain value'
      );
    }

    this.$emit('mounted');
  },

  methods: {
    btnClick() {
      if (!this.loading) {
        this.$emit('btnClick');
      }
    },
  },
});
