
import { defineComponent, PropType } from 'vue';
import { NAMED_ROUTES } from '@white-label-configuration/constants';

// Types
import type { Policy, ManageBookingParsedOrderItem } from '@white-label-types/parking-booking';
import type { Partners } from '@white-label-types/partners';

// Store
import {
  readBookingStatus,
  readIsConfirmed,
  readIsInProgress,
  readIsCancelled,
  readIsPastBooking,
  readProductTypesCategories,
} from '@white-label-store/manage-booking';
import type { ManageBookingState as State } from '@white-label-types/stores';
import {
  goToApp,
  getDomainUrl,
  ECOMMERCE_URLS,
  DOMAINS_NAMES,
} from '@white-label-helper/switch-app';

// Helpers
import auth from '@white-label-helper/mixin-auth';
import { checkIsLessOneHour } from '@white-label-helper/time-helpers';
import { guessTimezone } from '@white-label-helper/date-utilities';
import { getAppVariable } from '@white-label-helper/get-app-variable';

import BookingStatus from '../booking-status/booking-status.vue';
import BTicketAdditionalInfo from '../b-ticket-additional-info/b-ticket-additional-info.vue';
import BTicket from '../b-ticket/b-ticket.vue';
import ManageBookingShareButton from '../manage-booking-share-button/manage-booking-share-button.vue';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';
import Tooltip from '../tooltip/tooltip.vue';

export default defineComponent({
  name: 'ManageBookingOrderDetails',

  components: {
    BookingStatus,
    BTicketAdditionalInfo,
    BTicket,
    ManageBookingShareButton,
    Tooltip,
    IconButton: () => import('@white-label-icon/icon-button'),
  },

  mixins: [auth],

  props: {
    orderData: {
      type: Object as PropType<State['manageBookingState']>,
      required: true,
    },

    modifications: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    hideDirection(): boolean {
      if (!this.showMultipleOrderItems) return false
      return this.modifications?.hideDirections
    },
    showMultipleOrderItems(): boolean {
      return this.orderData.items.length > 1;
    },
    crossSellEnabled() {
      // TODO: Remove condition when ECM_2_CROSS_SELL_POST_PURCHASE is enabled
      return (
        getAppHeroProduct('parking') === 'parking' &&
        this.$launchDarkly.variation('ECM_2_CROSS_SELL_POST_PURCHASE')
      );
    },

    /**
     * Sort order items by entry date and time ascending.
     * If entry time is not provided, sort at the end, alphabetically by product code.
     */
    sortedOrderItems(): State['manageBookingState']['items'] {
      const items = [...this.orderData.items];
      return items.sort((a, b) => {
        const productCodeA = a.product_code;
        const productCodeB = b.product_code;

        const entryDateA = a.search_criteria[productCodeA]?.date1;
        const entryDateB = b.search_criteria[productCodeB]?.date1;

        const entryTimeA = a.search_criteria[productCodeA]?.time1;
        const entryTimeB = b.search_criteria[productCodeB]?.time1;

        const entryDateTimeA = `${entryDateA} ${entryTimeA}`;
        const entryDateTimeB = `${entryDateB} ${entryTimeB}`;

        if (!entryTimeA && !entryTimeB) {
          return productCodeA.localeCompare(productCodeB);
        } else if (!entryTimeA) {
          return 1;
        } else if (!entryTimeB) {
          return -1;
        } else {
          return (
            new Date(entryDateTimeA).getTime() -
            new Date(entryDateTimeB).getTime()
          );
        }
      });
    },

    getBookingStatus(): ReturnType<typeof readBookingStatus> {
      return readBookingStatus(this.$store);
    },

    isConfirmed(): ReturnType<typeof readIsConfirmed> {
      return readIsConfirmed(this.$store);
    },

    isInProgress(): ReturnType<typeof readIsInProgress> {
      return readIsInProgress(this.$store);
    },

    isCancelled(): ReturnType<typeof readIsCancelled> {
      return readIsCancelled(this.$store);
    },

    isPastBooking(): ReturnType<typeof readIsPastBooking> {
      return readIsPastBooking(this.$store);
    },

    cancellationPolicies(): Policy[] {
      if (this.orderData.cancellationProtection) {
        return this.orderData.cancellationProtection.cancellation_policies;
      }
      return this.orderData.cancellationPolicies || [];
    },

    subItems(): { title: string }[] {
      if (this.orderData.groups) {
        return this.orderData.groups.map((title: string) => ({ title }));
      }
      return [];
    },

    entryDateTime(): string {
      return this.orderData.entryDateTime || '';
    },
    exitDateTime(): string {
      return this.orderData.exitDateTime || '';
    },
    partnerTimeZone(): Partners['poi']['timezone'] {
      return getAppVariable('poi.timezone') || guessTimezone();
    },
  },

  methods: {
    itemIsParkingProduct(
      item: ManageBookingParsedOrderItem
    ): boolean {
      return item.product_code === 'parking';
    },
    itemIsLessOneHour(item: ManageBookingParsedOrderItem): boolean {
      return checkIsLessOneHour(item.entryExitInfo?.stringEntryTime, this.partnerTimeZone);
    },
    isProductTypeIncluded(key: string): boolean {
      return readProductTypesCategories(this.$store)?.includes(key);
    },
    cancelBookingHandler(item: ManageBookingParsedOrderItem) {
      this.$router.push({
        path: NAMED_ROUTES.manage_booking.cancel,
        query: { cancelItemId: item.id.toString() },
      });
    },
    amendBookingHandler(item: ManageBookingParsedOrderItem) {
      goToApp(
        getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
        ECOMMERCE_URLS.MODIFY,
        `amendItemId=${item.id}`
      );
    },
    itemIsPrivateTerminalProduct(
      item: ManageBookingParsedOrderItem
    ) {
      return item.inventory_item?.product_option?.code === 'lounge_private';
    },
  },
});
