
import { defineComponent } from 'vue';

import ReceiptPageHeadPreloader from '../receipt-page-head-preloader/receipt-page-head-preloader.vue';

export default defineComponent({
  components: {
    IconCheck: () => import('@white-label-icon/icon-check'),
    ReceiptPageHeadPreloader,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },

    contentLoading: {
      type: Boolean,
    },

    noContainer: {
      type: Boolean,
      default: false,
    },
  },
});
