// Types
import type { AuthMixin } from './mixin-auth.d';

const auth: AuthMixin = {
  computed: {
    auth0User() {
      return this.$auth?.user;
    },

    isAuth0UserLoggedIn() {
      return this.$auth && this.$auth.loggedIn;
    },

    /**
     * Check what the statue is of the token.
     * It could either be 'VALID', 'EXPIRED' or 'UNKNOWN'.
     * The status is also used to call methods, valid, expired and unknown.
     */
    getStatus() {
      // @ts-expect-error - Auth0 method with setters and getters
      return this.$auth.strategy.token.status();
    },

    /** Check to see if the current token is valid or not */
    tokenIsValid() {
      return this.getStatus.valid();
    },

    /** Returns the token bearer for the user that is logged in */
    getToken(): string {
      // @ts-expect-error - Auth0 method with setters and getters
      return this.$auth.strategy.token.get();
    },

  },

  methods: {
    async logout() {
      this.$auth.strategy.reset?.();
      await this.$auth.logout();
    },
  },
};

export default auth;
