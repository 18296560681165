
// Packages
import { defineComponent, PropType } from 'vue';

// Types
import type { CartItem } from '@white-label-types/parking-checkout';

// Components
import ExpansionPanel from '../expansion-panel/expansion-panel.vue';

export default defineComponent({
  components: {
    ExpansionPanel,
  },
  props: {
    cancellationPolicies: {
      type: Array as PropType<CartItem['inventory_option']['cancellation_policies']>,
      default: () => [],
    },
    amendmentPolicies: {
      type: Array as PropType<CartItem['inventory_option']['amendment_policies']>,
      default: () => [],
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    hasCancellationPolicies(): number {
      return this.cancellationPolicies.length;
    },

    hasAmendmentPolicies(): number {
      return this.amendmentPolicies.length;
    },

    hasCancellationOrAmendmentPolicies(): number {
      return this.hasCancellationPolicies || this.hasAmendmentPolicies;
    },
  },
});
