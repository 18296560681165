import { defineStore } from 'pinia';

import { getMarketingSettings } from '@white-label-helper/api-marketing-preference';
import type { MarketingPreferences } from '@white-label-types/parking-shared';


type MarketingPreferencesStoreState = {
  marketingPreferences: MarketingPreferences | null
}

/**
 * Marketing Preferences Account Store definition
 *
 * @returns {StoreDefinition}
 */
export const useMarketingPreferencesAccountStore = defineStore({
  id: 'marketing-preferences-account',

  actions: {
    async dispatchMarketingPreferences() {
      const response = await getMarketingSettings()
      this.marketingPreferences = response
    }
  },

  getters: {
    readMarketingStrategy: (state) => state.marketingPreferences?.strategy,
    readMarketingChannels: (state) => state.marketingPreferences?.marketing_channels,
  },

  state: () => {
    const defaultMarketingPreferencesAccountState: MarketingPreferencesStoreState = {
      marketingPreferences: null,
    }

    return defaultMarketingPreferencesAccountState;
  }
});
