import Vue, { VNodeDirective } from 'vue';

Vue.directive('scroll-lock', {
  bind(_el: HTMLElement, binding: VNodeDirective) {
    if (binding.value) {
      document.body.classList.add('body-overflow-hidden');
    } else {
      document.body.classList.remove('body-overflow-hidden');
    }
  },
  update(_el: HTMLElement, binding: VNodeDirective) {
    if (binding.value) {
      document.body.classList.add('body-overflow-hidden');
    } else {
      document.body.classList.remove('body-overflow-hidden');
    }
  },
  unbind() {
    document.body.classList.remove('body-overflow-hidden');
  },
});
