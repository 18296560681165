import { datadogRum } from '@datadog/browser-rum';
import Pusher from 'pusher-js';

import { getAppVariable } from '@white-label-helper/get-app-variable';

let _instance: Pusher | undefined;

/**
 * A helper function to reset the instance.
 * This is used for testing purposes only.
 */
export function _resetChannel(): void {
  if (process.env.NODE_ENV === 'production') {
    throw new Error('This function should not be called in production');
  }

  _instance = undefined;
}

/**
 * Get the pusher instance
 *
 * @returns {Pusher}
 */
export const getPusherHelper = () => {
  const regionalConfig = getAppVariable('regional_config');
  return getPusherInstance(regionalConfig.pusher.key, regionalConfig.pusher.cluster);
}

/**
 * Get the pusher instance
 *
 * This function will create the channel if it has not been created before. Subsequent calls will
 * return the same channel.
 *
 * See https://pusher.com/docs/channels/using_channels/connection/
 *
 * @returns {Pusher} The pusher instance
 */
export const getPusherInstance = (pusherKey: string, pusherCluster: string): Pusher => {
  if (_instance !== undefined) {
    return _instance;
  }

  _instance = new Pusher(pusherKey, {
    cluster: pusherCluster
  });

  // When a connection error occurs, log an error to the reporting platform.
  _instance.connection.bind('error', (error: unknown) => {
    datadogRum.addError(error);
  });

  return _instance;
}
