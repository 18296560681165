/**
 * @file Holds the instance for making the API call to get the marking preference settings
 */

import defaultDeep from 'lodash/defaultsDeep';
import BaseConfig from './http.base-instance-config';

const options = defaultDeep({
  baseURL: process.env['NUXT_ENV_MARKETING_API_URL'],
  headers: {
    Authorization: `Bearer ${process.env['NUXT_ENV_AFFILIATE_APPLICATION_TOKEN']}`,
  },
}, BaseConfig.options);

export default {
  interceptors: BaseConfig.interceptors,
  options,
};
