export function setupLinkProtocol(sourceLink: string): string | null {
  if (!sourceLink) return null;

  const availableProtocols = ['http:', 'https:'];

  const hasProtocol = availableProtocols.find((protocol) =>
    sourceLink.includes(protocol)
  );

  if (hasProtocol) return sourceLink;

  return `https://${sourceLink}`;
}
