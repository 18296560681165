export const typesOfIcon = {
  info: 'info',
  warning: 'warning',
  error: 'error',
  success: 'success',
  highlight: 'highlight',
} as const;
export const sizes = {
  big: 'big',
  full: 'full',
  small: 'small',
} as const;
