import Vue from 'vue';

const GlobalEventEmitter = new Vue();

Vue.mixin({
  computed: {
    $global_eventEmitter(): Vue {
      return GlobalEventEmitter;
    },
  },
});
