
import { defineComponent, PropType } from 'vue';
import type VueI18n from 'vue-i18n/types';
import { BOOKING_STATUSES } from '@white-label-configuration/constants';

type Config = {
  wrapperClass: string;
  circleClass?: string;
  label: VueI18n.TranslateResult;
};

type BookingStatues = keyof typeof BOOKING_STATUSES;

export default defineComponent({
  props: {
    status: {
      type: String as PropType<BookingStatues>,
      required: true,
    },
    small: {
      type: Boolean,
    },
    isMA: {
      type: Boolean,
    },
    withCircle: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    localConfig(): Config | null {
      return this.isMA ? this.configMA : this.config;
    },

    config(): Config | null {
      switch (this.status) {
        case BOOKING_STATUSES.CANCELLED:
          return {
            wrapperClass: 'bg-alert-8',
            circleClass: 'bg-alert-100',
            label: this.$t('UI.bookingStatuses.cancelled'),
          };
        case BOOKING_STATUSES.CONFIRMED:
          return {
            wrapperClass: 'bg-success-8',
            circleClass: 'bg-success-100',
            label: this.$t('UI.bookingStatuses.confirmed'),
          };
        case BOOKING_STATUSES.IN_PROGRESS:
          return {
            wrapperClass: 'bg-highlight-8',
            circleClass: 'bg-highlight-100',
            label: this.$t('UI.bookingStatuses.inProgress'),
          };
        case BOOKING_STATUSES.PAST_BOOKING:
          return {
            wrapperClass: 'bg-info-8',
            circleClass: 'bg-info-100',
            label: this.$t('UI.bookingStatuses.pastBooking'),
          };
        case BOOKING_STATUSES.incomplete:
          return {
            wrapperClass: 'bg-alert-8',
            circleClass: 'bg-alert-100',
            label: this.$t('UI.bookingStatuses.incomplete'),
          };
        case BOOKING_STATUSES.complete:
          return {
            wrapperClass: 'bg-success-8',
            circleClass: 'bg-success-100',
            label: this.$t('UI.bookingStatuses.complete'),
          };
        case BOOKING_STATUSES.new:
          return {
            wrapperClass: 'bg-alert-8',
            circleClass: 'bg-alert-100',
            label: this.$t('UI.bookingStatuses.new'),
          };
        case BOOKING_STATUSES.draft:
          return {
            wrapperClass: 'bg-highlight-8',
            circleClass: 'bg-highlight-100',
            label: this.$t('UI.bookingStatuses.draft'),
          };
        case BOOKING_STATUSES.edited:
          return {
            wrapperClass: 'bg-success-16',
            circleClass: 'bg-success-100',
            label: this.$t('UI.bookingStatuses.edited'),
          };
        case BOOKING_STATUSES.submitted:
          return {
            wrapperClass: 'bg-success-16',
            circleClass: 'bg-success-100',
            label: this.$t('UI.bookingStatuses.submitted'),
          };
        default:
          return null;
      }
    },
    configMA(): Config | null {
      switch (this.status) {
        case BOOKING_STATUSES.CANCELLED:
          return {
            wrapperClass: 'bg-alert-8',
            label: this.$t('UI.bookingStatuses.cancelled'),
          };
        case BOOKING_STATUSES.IN_PROGRESS:
          return {
            wrapperClass: 'bg-success-8',
            circleClass: 'bg-success-100',
            label: this.$t('UI.bookingStatuses.live'),
          };
        case BOOKING_STATUSES.PAST_BOOKING:
          return {
            wrapperClass: 'bg-mag-elements-dividers',
            label: this.$t('UI.bookingStatuses.pastBooking'),
          };
        case BOOKING_STATUSES.ACTION_REQUIRED:
          return {
            wrapperClass: 'bg-orange-8',
            circleClass: 'bg-info-100',
            label: this.$t('UI.bookingStatuses.actionRequired'),
          };
        default:
          return null;
      }
    },
  },
});
