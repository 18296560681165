import { defineStore } from 'pinia';

import { getUserMarketingSubscription } from '@white-label-helper/marketing-user';
import { getMarketingSettings } from '@white-label-helper/api-marketing-preference';
import type { CustomText, MarketingPreferences, UserMarketingSubscription } from '@white-label-types/parking-shared';


type MarketingPreferencesStoreState = {
  marketingPreferences: MarketingPreferences | null
  userMarketingSubscription: UserMarketingSubscription | null
  customText: CustomText | null
}

/**
 * Marketing Preferences Store definition
 *
 * @returns {StoreDefinition}
 */
export const useMarketingPreferencesStore = defineStore({
  id: 'marketing-preferences',

  actions: {
    async dispatchMarketingPreferences() {
      const response = await getMarketingSettings();
      this.marketingPreferences = response;
      return this.marketingPreferences;
    },

    async dispatchUserMarketingSubscription(email: string) {
      const response = await getUserMarketingSubscription(email);
      this.userMarketingSubscription = response;
      return this.userMarketingSubscription;
    }
  },

  getters: {
    readCustomerUUID: (state) => state.userMarketingSubscription?.customer_uuid,
    readEmail: (state) => state.userMarketingSubscription?.marketing_channels?.email,
    readMarketingPreferences: (state) => state.marketingPreferences,
    readMarketingStrategy: (state) => state.marketingPreferences?.strategy,
    readSms: (state) => state.userMarketingSubscription?.marketing_channels?.sms,
    readUserMarketingSubscription: (state) => state.userMarketingSubscription,
  },

  state: () => {
    const defaultMarketingPreferencesState: MarketingPreferencesStoreState = {
      customText: null,
      marketingPreferences: null,
      userMarketingSubscription: null,
    }

    return defaultMarketingPreferencesState;
  }
});
