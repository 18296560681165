
import { defineComponent, PropType } from 'vue';
import { PARKING_SORT_OPTIONS } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'SearchParkingSort',

  components: {
    IconArrowDown: () => import('@white-label-icon/icon-arrow-down'),
    IconButton: () => import('@white-label-icon/icon-button'),
    IconCheck: () => import('@white-label-icon/icon-check'),
    IconSort: () => import('@white-label-icon/icon-sort'),
  },

  props: {
    sortOptions: {
      type: Array as PropType<string[]>,
      required: true,
    },

    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      listShown: false,
      PARKING_SORT_OPTIONS,
    };
  },

  computed: {
    isScrollLocked(): boolean {
      return (this.isMobileResolution || this.isTabletResolution) && this.listShown;
    },
  },

  methods: {
    onOptionSelect(option: string): void {
      this.$emit('input', option);
      this.listShown = false;
    },
  },
});
