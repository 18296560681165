import paymentProviders from './payment-providers.config';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';

export function getPaymentProvider() {
  if (isBookingPortal) {
    return []
  }
  if (process.env.NUXT_ENV_PAYMENT_PROVIDER && process.env.NUXT_ENV_PAYMENT_PROVIDER in paymentProviders) {
    return paymentProviders[process.env.NUXT_ENV_PAYMENT_PROVIDER];
  }
  throw new Error('Payment provider is undefined. Check the PAYMENT_PROVIDER in env and payment-providers.config.js.');
}
