import type { Context } from '@white-label-types/middleware';
import { getFeatureFlag } from '@white-label-plugin/launch-darkly';

/**
 * Checks if there is an active maintenance mode flag, if so then redirects the user to the `/maintenance` route.
 *
 * For details on router middleware see https://v2.nuxt.com/docs/directory-structure/middleware#router-middleware
 * For details on feature toggles see https://docs.launchdarkly.com/sdk/client-side/javascript#getting-started
 *
 * @param {Context} context
 * @returns {Promise<any>}
 */
const routerMiddlware = async ({ redirect, route }: Context): Promise<void> => {
  if (process.server) {
    return;
  }

  if (route.path !== '/maintenance' && getFeatureFlag('WL-GlobalMaintenanceMode') === true) {
    // NOTE: this eslint rule is disabled as Nuxt expects there to be different return types for router middleware
    // eslint-disable-next-line consistent-return
    return redirect('/maintenance');
  }

  if (route.path === '/maintenance' && getFeatureFlag('WL-GlobalMaintenanceMode') === false) {
    // NOTE: this eslint rule is disabled as Nuxt expects there to be different return types for router middleware
    // eslint-disable-next-line consistent-return
    return redirect('/');
  }
};

export default routerMiddlware;
