import Vue from 'vue';
import { NAMED_ROUTES } from '@white-label-configuration/constants';

Vue.mixin({
  computed: {
    $isLoggedIn(): boolean {
      // @ts-expect-error - This should have auto picked up the types from the auth module, guessing it's due to it being in
      return this.$auth.state?.loggedIn;
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $loggedUser(): any {
      // @ts-expect-error - This should have auto picked up the types from the auth module, guessing it's due to it being in
      return this.$auth?.state?.user;
    },
  },

  methods: {
    $goHome() {
      this.$router.push(NAMED_ROUTES.home);
    },

    $openModalAfterRedirect(modalData, callback) {
      this.$store.commit('modalData/storeModalData', modalData);
      callback();
    },
  },
});
