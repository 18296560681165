
import { defineComponent, PropType } from 'vue';
import { Locale } from '@white-label-types/internationalization';
import { DropdownWrapper } from 'ui-shared-components';

export default defineComponent({
  name: 'LanguageSelector',

  components: {
    DropdownWrapper,
    IconCheck: () => import('@white-label-icon/icon-check'),
    IconFlag: () => import('@white-label-icon/icon-flag'),
  },

  props: {
    languages: {
      type: Array as PropType<Locale[]>,
      required: true,
    },
    selectedLanguage: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dropdownOpen: false,
    };
  },

  computed: {
    sortedLanguages() {
      return this.languages
        .sort((a, b) => a.name_native.localeCompare(b.name_native))
        .map((language) => ({
          code: language.code,
          name: language.name_native,
        }));
    },

    selectedLocale() {
      return this.sortedLanguages.find(
        (language) => language.code === this.selectedLanguage
      );
    },
  },

  methods: {
    itemIsSelected(item: Locale) {
      return item.code === this.selectedLanguage;
    },

    changeLanguage(language: Locale) {
      this.$emit('change-language', language);
      this.dropdownOpen = false;
    },
  },
});
