
export default {
  name: 'SummarySubtitle',

  props: {
    name: {
      type: String,
      default: '',
    },
    price: {
      type: String,
      default: '',
    },
    propClass: {
      type: String,
      default: 'text-lg',
    },
  },
};
