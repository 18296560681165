// Packages
import { datadogRum } from '@datadog/browser-rum';

// Types
import type { ContinueBookingBody } from '@white-label-helper/api-parkings-bookings';
import type { default as Pusher } from 'pusher-js';

// Constants
import { pusherErrorTimeout } from '@white-label-configuration/constants';

// Helpers
import { continueBookingRequest } from '@white-label-helper/api-parkings-bookings';
import getPusherChannel from '@white-label-helper/pusher-channel';

type PusherEvent = {
  management_token: {
    token: string,
    expiration: number
  },
  order_reference: string;
}

let channel: Pusher;

export function continueBookingHelper(flowKey: 'original' | 'manageBooking') {
  let successEventName: string;
  let failedEventName: string;

  switch (flowKey) {
    case 'original': {
      successEventName = 'OrderCompletedEvent';
      failedEventName = 'OrderCreatingFailedEvent';
      break;
    }
    case 'manageBooking': {
      successEventName = 'OrderAmendmentCompletedEvent';
      failedEventName = 'OrderUpdatingFailedEvent';
      break;
    }
    default: break;
  }

  let channelName: string;
  let timeoutId: NodeJS.Timeout;

  function destroyContinueBooking() {
    if (channel === undefined) {
      channel = getPusherChannel();
    }

    channel.unsubscribe(channelName);
  }

  function continueBooking(cartToken: string, body: ContinueBookingBody) {
    return new Promise<PusherEvent>((resolve, reject) => {
      channelName = `order.${cartToken}`;

      if (channel === undefined) {
        channel = getPusherChannel();
      }

      const localPusher = channel.subscribe(channelName);

      localPusher.bind(successEventName, (data: PusherEvent) => {
        resolve(data);
      });

      localPusher.bind(failedEventName, (error: unknown) => {
        clearTimeout(timeoutId);
        reject(error);
      });

      localPusher.bind('error', (error: unknown) => {
        reject(error);
      });

      continueBookingRequest(body).catch((error: unknown) => {
        clearTimeout(timeoutId);
        reject(error);
      });

      // Default timeout to reject request if it's frozen
      timeoutId = setTimeout(() => {
        const oneSecond = 1000;
        reject(new Error(`Timed out after ${pusherErrorTimeout / oneSecond} seconds`));
      }, pusherErrorTimeout);
    })
      .catch((error: unknown) => {
        datadogRum.addError(`pusher-continue-booking: ${error}`);
      });
  }

  return {
    continueBooking,
    destroyContinueBoooking: destroyContinueBooking,
  };
}
