import { defineStore } from 'pinia';
import { ref } from 'vue';
export const useDeepLink = defineStore('deepLink', () => {
  const discount = ref<string | null>(null);
  function updateDiscount(payload: string) {
    discount.value = payload;
  }
  return {
    discount,
    updateDiscount,
  };
})
