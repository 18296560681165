
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'IconMenuRight',
  props: {
    width: {
      type: [String, Number] as PropType<string | number>,
      default: 16,
    },
    height: {
      type: [String, Number] as PropType<string | number>,
      default: 16,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
});
