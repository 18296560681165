
import { defineComponent } from 'vue';
/*
 * In order to use this component, it is required for this element
 * scrollable-modal__scroll-area
 * to set max-height less than the height of the content.
 *
 * If all content is placed in crabs of the height you specified,
 * the user will not be able to scroll through the content.
 *
 * slots
 * - header -> to add some custom elements to the sticky header
 * - footerButtons -> to add custom elements to the sticky footer
 * */
import ModalCenter from '../modal-center/modal-center.vue'

export default defineComponent({
  name: 'ScrollableBody',

  components: {
    IconClose: () => import('@white-label-icon/icon-close'),
    ModalCenter,
  },

  props: {
    closeButtonId: {
      type: String,
      default: '',
    },
    closeOnClickOutside: {
      type: Boolean,
      default: false,
    },
    showCloseIcon: {
      type: Boolean,
      default: false,
    },
    modalClasses: {
      type: String,
      default: '',
    },
    propClass: {
      type: String,
      default: '',
    },
    isStickyHeaderTransparent: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      scrollListenerAdded: false,
      scrollAtTheTop: true,
      hideScrollBarUnderHeader: true,
    };
  },

  watch: {
    screenSize: 'toggleScrollBarVisibility',
  },

  methods: {
    toggleScrollBarVisibility() {
      // Put to end of event loop
      setTimeout(() => {
        if (this.$refs?.scrollAreaBody) {
          const scrollAreaBody: HTMLElement = this.$refs
            .scrollAreaBody as HTMLElement;
          const scrollBarState = this.$vuebar.getState(scrollAreaBody);

          this.hideScrollBarUnderHeader = scrollBarState.barHeight > 0;
        }
      }, 0);
    },

    scrollListener() {
      const element = this.$refs.scrollArea as HTMLElement;

      this.scrollAtTheTop = element.scrollTop === 0;
    },
  },
});
