
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ManageBookingNavigationItem',

  components: {
    IconButton: () => import('@white-label-icon/icon-button'),
  },

  props: {
    buttonGaId: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
  },
});
