
import { PropType, defineComponent } from 'vue';
import VueI18n from 'vue-i18n';

export default defineComponent({
  components: {
    IconArrow: () => import('@white-label-icon/icon-arrow'),
  },

  props: {
    title: {
      type: String as PropType<string | VueI18n.TranslateResult>,
      required: true,
    },

    isFullColor: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether to show the dropdown panel bar or not
     */
    showDropdown: {
      type: Boolean,
      default: true,
    },

    showDropdownExpanded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isExpanded: this.showDropdownExpanded,
    };
  },

  methods: {
    expand(): void {
      this.isExpanded = !this.isExpanded;
    },
    handleBodyClick(): void {
      if (this.isFullColor) {
        this.expand();
      }
    },
  },
});
